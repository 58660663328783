<template>
  <div id="app" :style="cssVars" @gesturestart="handleGestureStart" @gesturechange="handleGestureChange">
    <router-view/>
    <Alert />
  </div>
</template>

<script>
import Alert from './components/alerts/Alerts.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  components: {
    Alert
  },
  methods: {
    handleGestureStart (event) {
      event.preventDefault()
    },
    handleGestureChange (event) {
      event.preventDefault()
    }
  },
  metaInfo: {
    title: 'Pesquisa',
    link: [
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' }
    ],
    script: [
      { type: 'text/javascript', src: 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js' }
    ]
  },
  computed: {
    ...mapGetters({
      survey: 'survey/getSurvey'
    }),
    cssVars () {
      return {
        '--arrowColor': this.survey.colors.arrowColor,
        '--bodyColor': this.survey.colors.bodyColor,
        '--buttonBg': this.survey.colors.buttonBg,
        '--buttonColor': this.survey.colors.buttonColor,
        '--endMessageBg': this.survey.colors.buttonColor,
        '--endMessageColor': this.survey.colors.endMessageColor,
        '--footerBg': this.survey.colors.footerBg,
        '--headerBg': this.survey.colors.headerBg,
        '--headerColor': this.survey.colors.headerColor,
        '--splashBg': this.survey.colors.splashBg,
        '--splashColor': this.survey.colors.splashColor,
        '--surveyBg': this.survey.colors.surveyBg
      }
    }
  }
}
</script>

<style lang="scss">
* {
  button {
    border: none;
    outline: none;
  }
}
@import '@/sass/_reset.scss';
@import '@/sass/hover-grow.scss';
@import '@/sass/keyframes.scss';
@import "vue-select/src/scss/vue-select.scss";

#app {
  overflow-x: hidden;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'bicons';
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.eot');
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.svg') format('svg'),
    url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
</style>
