const state = emptyPrevQuestion()

function emptyPrevQuestion () {
  return {
    question: {
      type: 'nps',
      ui: '10num',
      tooltipOpen: false,
      icon: 'blur_on',
      title: 'Clique aqui para adicionar o título',
      description: 'Clique aqui para adicionar uma descrição',
      id: 0
    }
  }
}
const mutations = {
  question (state, payload) {
    state.question = payload
  }
}

const getters = {
  getPrevQuestions: state => state.question

}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
