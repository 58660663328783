<template>
<div class="container-end" v-if="mountedSurvey" :style="{'min-height': isMobile, '--body-height': dynamicHeight }">
  <div class="content-end" :style="{'min-height': isMobile}">
    <div class="header-end"></div>
    <div class="body-end">
      <div class="image-end">
        <img :src="returnImage()" alt="" v-if="!mountedMessage.logo.isHidden && returnImage()">
      </div>
      <div class="message-end">
        <p class="text-message-end" v-if="mountedMessage && mountedMessage.message" v-html="replaceMeta(mountedMessage.message)"></p>
      </div>
      <div class="link-end" v-if="mountedMessage.details &&  mountedMessage.details.link && mountedMessage.details.link.value">
        <p class="message-detail-end" v-if="mountedMessage && mountedMessage.details">{{ mountedMessage.details.value }}</p>
        <a :href="mountedMessage.details.link.value" target="_blank" rel="noopener noreferrer">{{ mountedMessage.details.link.label }}</a>
      </div>
      <redirect-component :message="mountedMessage" v-if="redirect"/>
    </div>
    <div class="animation active" v-html="myAnimation.html"></div>
    <div class="footer-end">
      <a v-if="showBindsLogoForEndMessage" target="_blank" href="https://binds.co/pesquisa/conheca-a-binds-co/">
        <img class="img-binds-logo" src="../assets/binds-end-message-logo.svg" alt="logo-binds">
      </a>
    </div>
  </div>
</div>
</template>

<script>
import { surveyService } from '@/_services'
import { getAnimations } from '@/_helpers'
import { replaceMetadata } from '../_helpers/metadata'
import '../sass/animations/tilesPage.scss'

export default {
  name: 'End',
  props: ['survey', 'question', 'response'],
  components: {
    'redirect-component': () => import('@/components/messages/Redirect.vue')
  },
  data () {
    return {
      isMobile: '',
      headerHeight: '',
      dynamicHeight: '',
      isPreview: false,
      showPage: false,
      myAnimation: {
        show: false,
        name: '',
        html: ''
      },
      message: '',
      firstQuestion: '',
      redirect: false
    }
  },
  created () {
    this.returnScreen()
    this.isPreview = this.$store.getters['preview/getPreviewFlag']
    if (this.mountedMessage.redirectUrl && this.mountedMessage.redirectUrl !== undefined) {
      this.redirect = true
      const url = this.mountedMessage.redirectUrl
      const time = this.mountedMessage.redirectSec
      setTimeout(function () {
        window.location.href = url
      }, time * 1000)
    } else {
      this.redirect = false
      if (this.mountedSurvey.loop) {
        setTimeout((self) => {
          self.$router.push({
            name: 'Start',
            params: self.mountedSurvey.initialRoute.params,
            query: self.mountedSurvey.initialRoute.query
          })
        }, 2 * 1000, this)
      }
    }
  },
  async mounted () {
    this.returnAnimation()
    this.returnScreen()
    const sendingId = this.$store.getters['seedData/getSendingId']

    if (!sendingId) {
      console.error('missing sending id')
      return this.$store.commit('alerts/alert', {
        showAlert: true,
        message: this.$t('error')
      })
    }

    if (!this.isPreview) { await surveyService.done(sendingId) }
  },
  computed: {
    showBindsLogoForEndMessage () { return this.$store.getters['short/getShort'].data.showBindsLogoForEndMessage },
    account () { return this.$store.getters['account/getAccount'] },
    mountedMessage () { return this.$store.getters['end/getEndMessage'][0] },
    mountedSurvey () {
      let survey = this.$store.getters['survey/getSurvey']

      if (this.isPreview) { survey = this.$store.getters['preview/getSurvey'] }

      return survey
    },
    seedMetadata () {
      let data = this.$store.getters['seedData/getSeedData']

      if (this.isPreview) { data = this.$store.getters['preview/getSeedData'] }

      this.$store.commit('seedData/seed', {})

      return data && data.metadata ? data.metadata : {}
    }
  },
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      if (screenWidth < 850) {
        this.headerHeight = '140px'
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${screenHeight - 240}px`
      }
    },
    returnAnimation () {
      let myTransition
      if (this.mountedSurvey.animation) {
        if (this.mountedSurvey.animation.isActive) {
          if (this.mountedSurvey.animation.name === 'insideBox') {
            this.myAnimation.show = true
            this.myAnimation.name = 'insideBox'
            myTransition = document.querySelector('.content-start')
            const first = 'closing'

            if (myTransition.classList.contains(first)) {
              setTimeout(() => {
                myTransition.classList.remove(first)
              }, 100)
            }
          }

          if (this.mountedSurvey.animation.name === 'tilesPage') {
            this.myAnimation.show = true
            this.myAnimation.name = 'tilesPage'
            this.myAnimation.html = getAnimations.returnAnimation(this.myAnimation, this.mountedSurvey)
            const animation = document.querySelector('.animation')
            if (this.mountedSurvey.animation.isActive) {
              setTimeout(() => {
                animation.classList.remove('active')
              }, 100)
            }
          }
        }
      } else {
        return false
      }
    },
    replaceMeta (str) {
      return replaceMetadata(str, this.seedMetadata, this.answerList).replace(/\n/gi, '<br />')
    },
    returnImage () {
      let image = this.mountedMessage.logo && this.mountedMessage.logo.url ? this.mountedMessage.logo.url : ''
      if (!image) {
        image = this.mountedSurvey.images && this.mountedSurvey.images.length > 0 ? this.mountedSurvey.images[0].value : ''
      }
      return image
    }
  }
}
</script>
<style lang="scss">
.container-end {
  background: var(--bodyColor);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-end {
    position: relative;
    width: 650px;
    min-height: 650px;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .header-end {
      width: 100%;
      height: 140px;
      background: var(--headerBg);
    }
    .body-end {
      background: var(--splashBg);
      width: 100%;
      min-height: 410px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .image-end {
        width: 100%;
        min-height: 200px;
        max-height: 410px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 465px;
          max-height: 300px;
        }
      }
      .message-end {
        margin: 10px 0;
        padding: 0 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        p {
          font-size: 1.4rem;
          color: var(--splashColor);
        }
      }
      .link-end {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        a {
          margin: 10px;
        }
      }
      .message-detail-end {
        font-size: 15px;
        text-align: center;
      }
    }
    .footer-end {
      width: 100%;
      height: 100px;
      background: var(--footerBg);
      padding-bottom: 1rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .img-binds-logo {
        width: 65px;
      }
    }
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-end {
    width: 100vw !important;
    border-radius: 0 !important;
    .body-end {
      padding-bottom: 10px;
      min-height: var(--body-height) !important;
      .image-end {
        img {
          max-height: 275px !important;
          max-width: 340px !important;
        }
      }
      .message-end {
        width: 99vw;
        padding: 0 5px !important;
        .text-message-end {
          font-size: 1.2rem !important;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
