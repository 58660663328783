import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
// import Store from '@/store/'
import Preview from '@/pages/Preview'
import Survey from '@/pages/Survey'
import Start from '@/pages/Start'
import Sending from '@/pages/Sending'
import Answered from '@/pages/Answered'
import Expired from '@/pages/Expired'
import NotFound from '@/pages/NotFound'
import Unsubscribe from '@/pages/Unsubscribe'
import End from '@/pages/End'

Vue.use(Router)
Vue.use(Meta)

export const router = new Router({
  routes: [
    {
      path: '/preview/:id',
      name: 'Preview',
      component: Preview
    },
    {
      path: '/survey/:seeds/:sending/:question/:order',
      name: 'Survey',
      props: true,
      component: Survey
    },
    {
      path: '/link/:seeds',
      name: 'Start',
      props: true,
      component: Start
    },
    {
      path: '/:sendingId',
      name: 'Sending',
      props: true,
      component: Sending
    },
    {
      path: '/:sendingId/:order',
      name: 'Sending2',
      props: true,
      component: Sending
    },
    {
      path: '/answered/',
      name: 'Answered',
      component: Answered
    },
    {
      path: '/end/',
      name: 'End',
      component: End
    },
    {
      path: '/expired/',
      name: 'Expired',
      component: Expired
    },
    {
      path: '/notFound/',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/optout/unsubscribe/:id',
      query: '',
      name: 'Unsubscribe',
      component: Unsubscribe
    },
    {
      path: '*',
      redirect: '/notFound/',
      component: NotFound
    }
  ]
})
