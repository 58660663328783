
const state = {
  endMessage: {
    type: 'end',
    title: 'Obrigado!',
    text: 'Digite aqui o texto de encerramento',
    useLink: false,
    linkLabel: '',
    link: '',
    imageUrl: '',
    uuid: ''
  }
}

const mutations = {
  setEndMessage (state, payload) {
    state.endMessage = payload
  }
}

const getters = {
  getEndMessage: state => state.endMessage
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
