<template>
  <div class="container-loading" :style="{'background': showLoad ? this.colors.splashBg : '#transparent'}">
    <div class="loading">
      <span class="loading__anim" v-show="showIcon"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLoading',
  props: ['showLoad', 'showIcon', 'colors'],
  components: {},
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.container-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  max-width: 50%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.loadWords {
  font-weight: normal;
  font-size: 0.9rem;
  color: rgba(189, 189, 189, 1);
  margin: 0.6rem 0 2rem 0;
  display: block;
}

.loading__anim {
  width: 45px;
  height: 45px;
  border: 4px solid rgba(189, 189, 189, 0.25);
  border-left-color: var(--headerBg);
  border-top-color: var(--headerBg);
  border-radius: 50%;
  display: inline-block;
  animation: rotate 800ms infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}
</style>
