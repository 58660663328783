const moment = require('moment')
export const validateSending = {
  isExpiredAt
}

/**
 * @param {String} str seconds, minutes, weeks, days, months
 */
function isExpiredAt (sending) {
  const { expireInterval, createdAt } = sending
  if (!expireInterval || !createdAt) return false // bypass

  const res = getPeriod(expireInterval)
  if (!res.period) return false

  const sendingDate = moment(new Date())
  const expireTime = moment(createdAt)
  expireTime.add(res.time, res.period)
  return !sendingDate.isSameOrBefore(expireTime) // se a data for anterior então negamos o retorno para dizer que `n está expirado`
}

/**
 * @param {String} str seconds, minutes, weeks, days, months
 */
function getPeriod (str) {
  try {
    return { time: str.match(/\d+/g)[0], period: str.match(/[a-zA-Z]+/g)[0] }
  } catch (error) {
    return { time: 0, period: '' }
  }
}
