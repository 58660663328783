const state = emptySeedData()

function emptySeedData () {
  return {
    seed: { from: {}, metadata: {} },
    sendingId: '',
    expiredSurveyData: ''
  }
}

const mutations = {
  seed (state, payload) {
    state.seed = payload
  },
  updateExpiredSurvey (state, payload) {
    state.expiredSurveyData = payload
  },
  setSendingId (state, payload) {
    state.sendingId = payload
  },
  clearSeedDataState (state, params) {
    state = emptySeedData()
  }
}

const getters = {
  getSeedData: state => state.seed,
  getSendingId: state => state.sendingId,
  getExpiredSurveyData: state => state.expiredSurveyData
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
