const state = emptyQuestion()

function emptyQuestion () {
  return {
    question: [
      {
        type: 'nps',
        ui: '10num',
        tooltipOpen: false,
        icon: 'blur_on',
        title: 'Clique aqui para adicionar o título',
        description: 'Clique aqui para adicionar uma descrição',
        id: ''
      }
    ]
  }
}
const mutations = {
  setQuestion (state, payload) {
    state.question = payload
  },
  clearQuestionState (state, params) {
    state = emptyQuestion()
  }
}

const getters = {
  getQuestion: state => state.question

}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
