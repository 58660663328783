export default {
  redirect: 'Serás redirigido ao',
  expired: 'Esta encuesta ya ha caducado.',
  previousQuestion: 'Anterior',
  vu: 'Muy insatisfecho',
  un: 'Insatisfecho',
  ind: 'Indiferente',
  sat: 'Satisfecho',
  satisf: 'Satisfactorio',
  vs: 'Muy satisfecho',
  vb: 'Muy malo',
  bd: 'Malo',
  st: 'Bueno',
  vg: 'Muy bueno',
  ex: 'Excelente',
  le: 'Pequeño esfuerzo',
  me: 'Mucho esfuerzo',
  ee: 'Esfuerzo esperado',
  op1: 'Sí  ',
  op2: 'No',
  op3: 'No lo sé',
  op4: 'Tal vez',
  op: 'Opción',
  required: 'Complete los campos obligatorios',
  other_required: 'Complete el campo',
  multiple_answer_required: 'Debe seleccionar entre',
  multiple_and: 'y',
  multiple_options: 'opciones',
  loading: 'Cargando',
  answered: 'Esta encuesta ya ha sido respondida',
  something_is_wrong: 'Algo está mal!',
  max_length: 'Caracteres máximos',
  unsubscribe: {
    title: '¿Realmente quieres darte de baja?',
    yes: 'SI',
    no: 'NO',
    thanks: '¡Gracias!',
    done: 'Ya no estás suscrito, si quieres volver a suscribirte solo ingresa a <a href="https://binds.co"><b>contáctanos</b></a>.'
  },
  inativity: {
    are_you_there: 'Está ahí ?',
    redirect_idle: 'Reiniciando la búsqueda en:'
  },
  multiple: {
    field: 'campo',
    fields: 'campos',
    min: 'Elegir al menos',
    max: 'Elegir máximo'
  },
  ces: {
    totally_disagree: 'Desacuerdo totalmente',
    disagree: 'Estoy en desacuerdo',
    partially_disagree: 'Parcialmente en desacuerdo',
    neutral: 'Neutral',
    partially_agree: 'Parcialmente de acuerdo',
    agree: 'Estoy de acuerdo',
    totally_agree: 'Concuerdo totalmente'
  },
  nvs: {
    nvs_op1: 'Significativamente mejor',
    nvs_op2: 'Un poco mejor',
    nvs_op3: 'Ni mejor, ni peor',
    nvs_op4: 'Un poco peor',
    nvs_op5: 'Significativamente peor'
  },
  referral: {
    alert_email: 'Email inválido',
    alert_phone: 'Teléfono inválido'
  },
  error: 'Algo salió mal',
  survey_not_found: 'Búsqueda no encontrada',
  back_question: 'Volver'
}
