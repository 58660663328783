<template>
  <div class="container-answered" :style="{'min-height': isMobile, '--body-height': dynamicHeight }">
    <div class="content-answered" :style="{'min-height': isMobile}">
      <div class="header-answered"></div>
      <div class="body-answered">
        <div class="image-answered">
          <img :src="mountedSurvey.images[0].value" alt="" v-if="mountedSurvey.images && mountedSurvey.images[0]">
        </div>
        <div class="message-answered">
          <p v-if="mountedMessage && mountedMessage.title" v-html="replaceMeta(mountedMessage.title)"></p>
          <p class="message-detail-answered" v-if="mountedMessage && mountedMessage.details">{{ mountedMessage.details.value }}</p>
        </div>
        <div class="link-answered" v-if="mountedMessage.details &&  mountedMessage.details.link && mountedMessage.details.link.value">
          <redirect-component :message="mountedMessage.details" v-if="redirect"/>
          <a :href="mountedMessage.details.link.value" target="_blank" rel="noopener noreferrer">{{ mountedMessage.details.link.label }}</a>
        </div>
        <a v-if="showBindsLogoForEndMessage" class="logo-binds-end" target="_blank" href="https://binds.co/pesquisa/conheca-a-binds-co/">
          <img class="img-binds-logo" src="../assets/binds-end-message-logo.svg" alt="logo-binds">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceMetadata } from '../_helpers/metadata'

export default {
  name: 'Answered',
  props: ['survey', 'question', 'response'],
  components: {
    'redirect-component': () => import('@/components/messages/Redirect.vue')
  },
  data () {
    return {
      isMobile: '',
      headerHeight: '',
      dynamicHeight: '',
      message: '',
      firstQuestion: '',
      redirect: false
    }
  },
  computed: {
    mountedSurvey () { return this.$store.getters['survey/getSurvey'] },
    account () { return this.$store.getters['account/getAccount'] },
    mountedMessage () { return this.$store.getters['answered/getAnsweredMessage'] },
    handlerShort () { return this.$store.getters['short/getShort'].data || {} },
    showBindsLogoForEndMessage () { return this.handlerShort.showBindsLogoForEndMessage },
    metadata () { return this.handlerShort.seedData ? this.handlerShort.seedData.metadata : {} }
  },
  created () {
    if (this.mountedMessage.details.redirectUrl) {
      this.redirect = true
      const url = this.mountedMessage.details.redirectUrl
      const time = this.mountedMessage.details.redirectSec
      setTimeout(function () {
        window.location.href = url
      }, time * 1000)
    } else {
      this.redirect = false
    }
  },
  mounted () {
    this.returnScreen()
  },
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      if (screenWidth < 500) {
        this.headerHeight = '140px'
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${screenHeight - 140}px`
      }
    },
    replaceMeta (str) {
      return replaceMetadata(str, this.metadata, this.answersList)
    }
  }
}
</script>
<style lang="scss">
.container-answered {
  background: var(--bodyColor);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-answered {
    background: wheat;
    width: 650px;
    min-height: 650px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .header-answered {
      width: 100%;
      height: 140px;
      background: var(--headerBg);
    }
    .body-answered {
      background: white;
      width: 100%;
      min-height: 510px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .image-answered {
        width: 100%;
        min-height: 200px;
        max-height: 410px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 520px;
          max-height: 300px;
        }
      }
      .message-answered {
        min-height: 80px;
        padding: 0 20px;
        text-align: center;
        p {
          font-size: 1.3rem;
          color: var(--splashColor);
        }
        .message-detail-answered {
          margin-top: 10px;
          font-size: 15px;
        }
      }
      .link-answered {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          color: var(--splashColor);
        }
      }
      .logo-binds-end {
        .img-binds-logo {
          width: 65px;
        }
      }
    }
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-answered {
    width: 100vw !important;
    border-radius: 0 !important;
    .body-answered {
      min-height: var(--body-height) !important;
      .image-answered {
        img {
          max-width: 85% !important;
        }
      }
    }
  }
}
</style>
