const state = emptyPreviewState()

function emptyPreviewState () {
  return {
    isSurveyPreview: false,
    previousResponses: [],
    currentQuestion: {},
    seedData: {
      from: {},
      metadata: {}
    },
    survey: {
      account: '',
      answeredMessage: '',
      colors: [],
      endMessages: [],
      fonts: [],
      closeSurvey: '',
      loop: '',
      images: [],
      isLocked: true,
      nextLabel: '',
      questions: [],
      startMessage: {},
      surveyAlreadyResponded: false,
      title: '',
      _id: ''
    }
  }
}

const mutations = {
  setPreviewFlag (state, payload) {
    state.isSurveyPreview = payload
  },
  addPreviousResponse (state, payload) {
    state.previousResponses.push(payload)
  },
  removePreviousResponse (state, idx) {
    state.previousResponses.splice(idx, 1)
  },
  clearPreviousList (state, payload) {
    state.previousResponses = []
  },
  setCurrentQuestion (state, payload) {
    state.currentQuestion = payload
  },
  setSeedData (state, fields) {
    state.seedData = fields
  },
  setSurvey (state, survey) {
    Object
      .keys(survey)
      .forEach(key => {
        const value = survey[key]
        state.survey[key] = value
        if (key === 'colors' && value.length) {
          state.survey[key] = value.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        }
      })
  }
}

const getters = {
  getPreviewFlag: state => state.isSurveyPreview,
  getPreviousResponses: state => state.previousResponses,
  getCurrentQuestion: state => state.currentQuestion,
  getSeedData: state => state.seedData,
  getSurvey: state => state.survey
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
