import Vue from 'vue'
import App from './App'
import { router } from './router'
import store from './store'
import i18n from './translations/i18n'
import IdleVue from 'idle-vue'
import 'vue-select/dist/vue-select.css'

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000,
  startAtIdle: false
})

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
