import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import account from './modules/account'
import survey from './modules/survey'
import alerts from './modules/alerts'
import question from './modules/question'
import end from './modules/end'
import answered from './modules/answered'
import seedData from './modules/seedData'
import PrevQuestion from './modules/prevQuestion'
import answersList from './modules/answersList'
import short from './modules/short'
import preview from './modules/preview'

Vue.use(Vuex)

// primeira vez que entrar
// "http://localhost:8081/#/link/loJyx6dQY9rkwzEbYwPMXW1qf5ryDqBVZaj/?noCache=true"
// "http://localhost:8081/#/63ee3439d540432ae65f537f/?noCache=true"

// após começar a pesquisa e recarregar a página
// "http://localhost:8081/#/survey/loJyx6dQY9rkwzEbYwPMXW1qf5ryDqBVZaj/63ef80bf335ccf75157a26d5/63e15651df02d51d821d06ae/1"
// "http://localhost:8081/#/survey/63ee343842276b2ae0172101/63ee3439d540432ae65f537f/63e15651df02d51d821d06ae/1"

let sendingId
const findLink = window.location.href.split('/').filter(i => i.length > 16 && /[A-Z]/g.exec(i))
const findSending = window.location.href.split('/').filter(i => i.length > 16)
const assumeThatWeHaveStorageConcurrency = window.location.href.includes('noCache=true')

if (assumeThatWeHaveStorageConcurrency) {
  sessionStorage.setItem('bindsNoCache', true)
}

if (sessionStorage.getItem('bindsNoCache')) {
  if (findSending.length > 0) sendingId = findSending.length >= 3 ? findSending[1] : findSending[0]
  if (findLink.length > 0) sendingId = findLink[0]
}

const store = {
  modules: {
    account,
    survey,
    question,
    PrevQuestion,
    answersList,
    end,
    answered,
    alerts,
    seedData,
    short,
    preview
  },
  plugins: [
    createPersistedState({
      key: sendingId ? `bindsAccount-${sendingId}` : 'bindsAccount',
      paths: ['account'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsShort-${sendingId}` : 'bindsShort',
      paths: ['short'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsSurvey-${sendingId}` : 'bindsSurvey',
      paths: ['survey'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsSeedData-${sendingId}` : 'bindsSeedData',
      paths: ['seedData'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsQuestion-${sendingId}` : 'bindsQuestion',
      paths: ['question'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsList-${sendingId}` : 'bindsList',
      paths: ['answersList'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: sendingId ? `bindsPreview-${sendingId}` : 'bindsPreview',
      paths: ['preview'],
      storage: window.sessionStorage
    })
  ]
}
export default new Vuex.Store(store)
