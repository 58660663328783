<template>
  <div class="container-survey" :style="{'min-height': isMobile, '--header-and-footer': headerHeight, '--body-height': dynamicHeight }">
    <div class="container-question" :style="{'min-height': isMobile}" v-show="!starting">
      <div class="animation active" v-html="myAnimation.html"></div>
      <default-loading v-if="loading" :showIcon="showIcon" :showLoad="showLoad" :colors="mountedSurvey.colors"/>
      <div class="header-question">
        <progress-bar v-if="mountedSurvey.showProgressBar" :total="mountedSurvey.questions.length" :colors="mountedSurvey.colors"/>
        <div class="back-question" v-if="orderQuestion > 1 && !mountedSurvey.hidePreviousButton">
          <button class="has-label-button" v-if="mountedSurvey.showPreviousButtonLabel" @click="before()">
            <i class="material-icons icon-header-previous">arrow_back_ios</i>
            <span class="label-previous-button">{{ $t('back_question') }}</span>
          </button>
          <i class="material-icons icon-header" v-else @click="before()">arrow_back_ios</i>
        </div>
        <div class="close-question">
          <i class="material-icons icon-header" v-if="mountedSurvey.closeSurvey" @click="closeSurvey()">close</i>
        </div>
        <div class="titles-question">
          <p v-if="mountedQuestion.label" v-html="replaceMeta(mountedQuestion.label)"></p>
          <p v-if="mountedQuestion.question" v-html="replaceMeta(mountedQuestion.question)"></p>
        </div>
      </div>
      <div class="dynamic-question"
        :is="helperAnswer()"
        :key="mountedQuestion._id"
        :question="mountedQuestion"
        :colors="mountedSurvey.colors || {}"
        @update-question="updateQuestion"
        @loading-question="loadingQuestion"
        @question-answered="next"
        @value-changed="valueChanged">
      </div>
      <content-footer :question="mountedQuestion" :survey="mountedSurvey" :key="refreshFooter" @footer-submit="response('')"></content-footer>
    </div>
    <idle />
  </div>
</template>

<script>
import { surveyService } from '@/_services'
import { responseHelper, translator, validateSending, getAnimations } from '@/_helpers'
import { replaceMetadata } from '../_helpers/metadata'
import '../sass/animations/tilesPage.scss'
import createActivityDetector from 'activity-detector'
import DefaultLoading from '../components/messages/DefaultLoading.vue'
import ProgressBar from '../components/messages/ProgressBar.vue'

export default {
  name: 'Survey',
  props: ['survey', 'question', 'response', 'colors'],
  components: {
    'content-footer': () => import('@/components/responseUi/footer.vue'),
    app5emo: () => import('@/components/responseUi/5emo.vue'),
    app5num: () => import('@/components/responseUi/5num.vue'),
    app10num: () => import('@/components/responseUi/10num.vue'),
    appynm: () => import('@/components/responseUi/ynm.vue'),
    appynd: () => import('@/components/responseUi/ynd.vue'),
    appyn: () => import('@/components/responseUi/yn.vue'),
    app1to7: () => import('@/components/responseUi/1to7.vue'),
    app1to7button: () => import('@/components/responseUi/1to7button.vue'),
    app5radio: () => import('@/components/responseUi/5radio.vue'),
    appmatrix10num: () => import('@/components/responseUi/matrix10num.vue'),
    appmatrix1to5: () => import('@/components/responseUi/matrix1to5.vue'),
    applike: () => import('@/components/responseUi/matrixlike.vue'),
    apptext: () => import('@/components/responseUi/text.vue'),
    appreferral: () => import('@/components/responseUi/referral.vue'),
    appreferraldynamic: () => import('@/components/responseUi/referraldynamic.vue'),
    appsingle: () => import('@/components/responseUi/single.vue'),
    appmultiple: () => import('@/components/responseUi/multiple.vue'),
    app0to10: () => import('@/components/responseUi/0to10.vue'),
    appblank: () => import('@/components/responseUi/blank.vue'),
    idle: () => import('@/components/alerts/Idle.vue'),
    Stretch: () => import('vue-loading-spinner/src/components/Circle.vue'),
    DefaultLoading,
    ProgressBar
  },
  data () {
    return {
      showIcon: true,
      showLoad: true,
      refreshFooter: 0,
      loading: false,
      isMobile: '',
      headerHeight: '',
      dynamicHeight: '',
      isIdle: null,
      myAnimation: {
        show: false,
        name: '',
        html: ''
      },
      starting: false,
      sendingId: '',
      currentValue: { rating: null }
    }
  },
  async mounted () {
    this.returnScreen()
    this.starting = true
    this.$root.$on('refresh-footer', payload => {
      this.refreshFooter++
    })

    // // INICIO da verificação de usuário inativo na survey
    if (this.mountedSurvey.initialRoute && this.mountedSurvey.initialRoute.query.timeout > 0) {
      const timeIdle = this.mountedSurvey.initialRoute.query.timeout * 1000
      const activityDetector = createActivityDetector({
        timeToIdle: timeIdle,
        ignoredEventsWhenIdle: []
      })

      if (timeIdle > 0) {
        activityDetector.on('idle', () => {
          this.$root.$emit('is-idle', { show: true })
        })
        activityDetector.on('active', () => {
          this.$root.$emit('is-idle', { show: false })
        })
      }
      this.$root.$on('stop-idle', () => {
        activityDetector.stop()
      })
    }
    // // FIM da verificação de usuário inativo na survey.

    this.setCurrentLanguage()
    // se nos params já tem sending, então não é a primeira resposta dessa pessoa
    // sendo assim o fluxo é outro

    let short = this.$store.getters['short/getShort']

    if (!short.data) {
      short = await surveyService.short(this.$route.params.sending)
      this.$store.commit('short/setShort', short)
    }

    this.returnAnimation(short.data)
    const isLink = this.$route.query.isLink
    if (this.$route.params.sending && !isLink) {
      if (!this.$route.query.r) {
        this.sendingId = this.$route.params.sending
        const sendingIdStore = this.$store.getters['seedData/getSendingId']
        if (!sendingIdStore) {
          this.$store.commit('seedData/setSendingId', this.sendingId)
        }
        return this.updatedCurrentSurveyOnState() // finaliza o fluxo se não tiver resposta na rota
      }
    } else {
      // caso ainda não tenha o sending
      const seedsId = this.$route.params.seeds
      if (!this.$route.query.r) {
        return this.updatedCurrentSurveyOnState() // finaliza o fluxo se não tiver resposta na rota
      } else {
        // checa se já n tem resposta na url, fazendo com que pule para a proxima question com a resposta que está no parametro "r"
        // basicamente isso faz a primeira resposta da survey ir automatica
        try {
          this.sendingId = this.$route.params.sending
          if (this.mountedQuestion && this.mountedQuestion.language) {
            this.setCurrentLanguage()
          }
          const formatedResponse = responseHelper.formatWhenIsAnsweredAutomaticWithUrlParam(this.mountedQuestion, this.sendingId, this.$route.query.r)
          const newObjQuestion = []
          if (this.mountedQuestion.ui === 'singleOther' && formatedResponse.isOther) {
            this.$router.push({
              name: 'Survey',
              params: {
                sending: this.sendingId,
                seeds: this.short.data.seed,
                question: this.mountedQuestion._id,
                order: 1
              }
            })
            return
          }
          const res = await surveyService.response(formatedResponse)
          if (res.data.nextQuestion) {
            newObjQuestion.push(res.data.nextQuestion)
            this.$store.commit('question/setQuestion', newObjQuestion)
            this.starting = false
            return this.$router.push({
              name: 'Survey',
              params: {
                sending: this.sendingId,
                seeds: seedsId,
                question: res.data.nextQuestion._id,
                order: 2
              }
            })
          }
          if (res.data.endMessage) {
            const endMessage = []
            endMessage.push(res.data.endMessage)
            this.$store.commit('end/setEndMessage', endMessage)
          }
        } catch (error) {
          console.log('Erro ao tentar responder automaticamente ', error)
        }
      }
    }
    this.starting = false
  },
  beforeDestroy () {
    this.$root.$off('is-idle')
    this.$root.$emit('stop-idle')
    this.$root.$off('refresh-footer')
    this.$root.$off('next-bar-progress')
    this.$root.$off('back-bar-progress')
    this.$root.$off('hidden-logo-footer')
  },
  computed: {
    orderQuestion () {
      return this.$route.params.order
    },
    mountedSurvey () {
      return this.$store.getters['survey/getSurvey']
    },
    questions () {
      return this.$store.getters['question/getQuestion']
    },
    seedData () {
      return this.$store.getters['seedData/getSeedData']
    },
    mountedQuestion () {
      const qs = this.$store.getters['question/getQuestion']
      const order = qs.length - 1
      const selected = qs[order]
      return selected
    },
    questionsList () {
      return this.$store.getters['question/getQuestion']
    },
    answersList () {
      return this.$store.getters['answersList/getAnswersList']
    }
  },
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      const headerHeight = document.querySelector('.header-question').clientHeight
      if (screenWidth < 850) {
        this.headerHeight = `${headerHeight + 140}px`
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${screenHeight - (headerHeight + 140)}px`
      }
    },
    returnAnimation (short) {
      let myTransition
      if (this.mountedSurvey.animation) {
        if (this.mountedSurvey.animation.isActive) {
          if (this.mountedSurvey.animation.name === 'insideBox') {
            this.myAnimation.show = true
            this.myAnimation.name = 'insideBox'
            myTransition = document.querySelector('.container-question')
            const first = 'closing'

            if (myTransition.classList.contains(first)) {
              setTimeout(() => {
                myTransition.classList.remove(first)
              }, 100)
            }
          }

          if (this.mountedSurvey.animation.name === 'tilesPage') {
            this.myAnimation.show = true
            this.myAnimation.name = 'tilesPage'
            this.myAnimation.html = getAnimations.returnAnimation(this.myAnimation, this.mountedSurvey)
            const animation = document.querySelector('.animation')
            if (this.mountedSurvey.animation.isActive) {
              setTimeout(() => {
                animation.classList.remove('active')
              }, 100)
            }
          }
        }
        return false
      } else if (short) {
        const surveyShort = short.survey
        if (surveyShort.animation) {
          this.$store.commit('survey/updateSurvey', { ...surveyShort, animation: surveyShort.animation })
          if (surveyShort.animation.name === 'insideBox') {
            this.myAnimation.show = true
            this.myAnimation.name = 'insideBox'
            myTransition = document.querySelector('.container-question')
            const first = 'closing'

            if (myTransition.classList.contains(first)) {
              setTimeout(() => {
                myTransition.classList.remove(first)
              }, 100)
            }
          }

          if (surveyShort.animation.name === 'tilesPage') {
            this.myAnimation.show = true
            this.myAnimation.name = 'tilesPage'
            this.myAnimation.html = getAnimations.returnAnimation(this.myAnimation, this.mountedSurvey)
            const animation = document.querySelector('.animation')
            if (surveyShort.animation.isActive) {
              setTimeout(() => {
                animation.classList.remove('active')
              }, 100)
            }
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    setCurrentLanguage () {
      this.$i18n.locale = translator.getCurrentLocale(this.mountedQuestion.language || 'pt-BR')
    },
    async updatedCurrentSurveyOnState () {
      let short = this.$store.getters['short/getShort']
      /*
        Checa se não tem short ainda no store, ou se tem e é short de um sendingId anterior (caso a pessoa
        cole o link na mesma aba que ele já abriu outra pesquisa) e faz a requisição novamente.
      */
      if (!short.data || short.data._id !== this.sendingId) {
        short = await surveyService.short(this.sendingId)
        this.$store.commit('short/setShort', short)
      }

      if (short.data) {
        const sendingIsExpired = validateSending.isExpiredAt({ expireInterval: short.data.survey.expireInterval, createdAt: short.data.createdAt })
        // correção de pesquisa expirada acessada por e-mail e por sms já funcionando aqui
        if (short.data.isExpired || sendingIsExpired) {
          this.$store.commit('seedData/updateExpiredSurvey', short.data.survey)
          setTimeout(() => { return this.$router.push({ name: 'Expired' }) }, 1500)
        }
        // correção para pesquisa de envio duplo, ja respondida por email ou sms
        const surveyAlreadyResponded = short.data.surveyAlreadyResponded || short.data.doneAt
        if (surveyAlreadyResponded) {
          const answeredMessage = {
            title: short.data.survey.answeredMessage,
            type: 'answered',
            details: short.data.survey.answeredMessageDetails,
            image: short.data.survey.images
          }
          this.$store.commit('answered/setAnsweredMessage', answeredMessage)
          this.$store.commit('survey/survey', short.data.survey)
          this.$store.commit('survey/setSurveyAlreadyResponded', surveyAlreadyResponded)
          setTimeout(() => { return this.$router.push({ name: 'Answered' }) }, 1500)
        } else {
          this.updateSurvey(short)
        }
      }
    },
    updateSurvey (short) {
      // todo esse if é feito para contornar um problema de survey desatualizada caso o sending acesse o link dela usando um id de pesquisa + question + order
      // isso força ele a pegar a survey sempre atualizada caso venha acessar o link q ele tenha copiado da url posteriormente (n é o fluxo padrão mas isso visa corrigir caso ele venha a usar dessa forma)
      const data = short.data
      let questions = []
      const seedsId = this.$route.params.seeds || data.seed
      this.$store.commit('survey/startEmptySurvey', '')
      this.$store.commit('survey/survey', data.survey)
      // store colors
      let firstQuestion = data.survey.questions[0]
      this.$store.commit('seedData/seed', short.data.seedData)
      if (this.$route.params.question) {
        const findedIndex = data.survey.questions.findIndex(question => { return question._id === this.$route.params.question })
        if (findedIndex >= 0) {
          firstQuestion = data.survey.questions[findedIndex]
        }
      }
      if (data.responses && data.responses.length > 0) {
        const arrayOfQuestion = []
        data.survey.questions.forEach(question => {
          if (firstQuestion._id !== question._id) {
            data.responses.forEach(questionResponded => {
              if (questionResponded.question && question._id === questionResponded.question._id) {
                arrayOfQuestion.push(question)
              }
            })
          }
        })
        questions = arrayOfQuestion
      }
      const questionAlreadyExists = this.$store.getters['question/getQuestion']
      questions.push(firstQuestion)
      this.$store.commit('question/setQuestion', questionAlreadyExists)
      this.starting = false // liberar a pagina
      this.$router.push({
        name: 'Survey',
        params: {
          sending: this.sendingId,
          seeds: seedsId,
          question: firstQuestion._id,
          order: this.$route.params.order
        }
      }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
    },
    replaceMeta (str) {
      const metadata = this.seedData && this.seedData.metadata ? this.seedData.metadata : {}
      return replaceMetadata(str, metadata, this.answersList)
    },
    valueChanged (e) {
      this.currentValue = e
    },
    helperAnswer () {
      if (this.mountedQuestion.ui) {
        let appUi = 'app' + this.mountedQuestion.ui.toLowerCase()
        if (this.mountedQuestion.type === 'matrix' && this.mountedQuestion.ui === '10num') {
          appUi = 'appmatrix10num'
        }
        if (this.mountedQuestion.ui === 'multipleOther') {
          appUi = 'appmultiple'
        }
        if (this.mountedQuestion.ui === 'singleOther') {
          appUi = 'appsingle'
        }
        if (this.mountedQuestion.type === 'enum' && this.mountedQuestion.ui === '10num') {
          appUi = 'app0to10'
        }
        return appUi
      }
    },
    loadingQuestion (e) {
      this.loading = e
    },
    async next () {
      this.loading = true
      if (this.myAnimation.show) {
        this.showIcon = false
        this.showLoad = false
      }

      if (this.myAnimation.show && this.myAnimation.name === 'insideBox') {
        this.showIcon = false
        this.showLoad = true
      }

      let myTransition
      let classTransition
      if (this.myAnimation.show) {
        if (this.myAnimation.name === 'insideBox') {
          myTransition = document.querySelector('.container-question')
          classTransition = 'closing'
        }

        if (this.myAnimation.name === 'tilesPage') {
          myTransition = document.querySelector('.animation')
          classTransition = 'active'
          this.$root.$emit('hidden-logo-footer', false)
        }
      }

      if (myTransition) {
        myTransition.classList.add(classTransition)
      }

      if (!this.sendingId && this.$route.params.sending) {
        this.sendingId = this.$route.params.sending
        const sendingIdStore = this.$store.getters['seedData/getSendingId']
        if (!sendingIdStore) {
          this.$store.commit('seedData/setSendingId', this.sendingId)
        }
      }
      let body
      body = { question: this.mountedQuestion._id, sending: this.sendingId }
      body = Object.assign(body, this.currentValue)
      const res = await surveyService.response(body)
      // TODO: depois vemos isso.
      // answersList
      const questionOptions = responseHelper.getOptions(this.mountedQuestion)
      if (['single', 'singleOther', 'multiple', 'multipleOther'].indexOf(this.mountedQuestion.ui) > -1 || ['matrix', 'referral'].indexOf(this.mountedQuestion.type) > -1) {
        this.insertAnswer(this.currentValue.value)
      } else if (this.mountedQuestion.ui === 'text') {
        if (this.currentValue.skip) {
          this.insertAnswer('')
        } else {
          if (this.currentValue.text.length > 100) {
            this.currentValue.text = this.currentValue.text.slice(0, 200) + '...'
          }
          this.insertAnswer(this.currentValue.text)
        }
      } else {
        if (this.currentValue.skip) {
          this.insertAnswer('')
        } else {
          const labelSelected = questionOptions.find(option => {
            let response = ''
            this.currentValue.rating >= 0 ? response = this.currentValue.rating.toString() : response = this.currentValue.value.toString()
            if (response) {
              if ((option.value || option.rating || option.label) === response) { return option }
            }
          })
          if (labelSelected !== undefined) {
            this.insertAnswer(labelSelected.label)
          } else {
            this.insertAnswer('')
          }
        }
      }

      const timeOut = myTransition ? 400 : 200

      setTimeout(() => {
        // final request
        this.currentValue = null
        this.$root.$emit('show-btn', false)
        this.updateQuestion(res.data)
        if (myTransition) {
          myTransition.classList.toggle(classTransition)
        }
      }, timeOut)
    },
    insertAnswer (value) {
      this.$store.commit('answersList/setAnswer', { id: this.mountedQuestion.id, value: value })
    },
    closeSurvey () {
      this.$store.commit('end/setEndMessage', this.mountedSurvey.endMessages)
      this.$router.push({ name: 'End' })
    },
    updateQuestion (e) {
      let myTransition
      let classTransition
      if (this.myAnimation.show) {
        if (this.myAnimation.name === 'insideBox') {
          myTransition = document.querySelector('.container-question')
          classTransition = 'closing'
        }

        if (this.myAnimation.name === 'tilesPage') {
          myTransition = document.querySelector('.animation')
          classTransition = 'active'
        }
      }

      if (myTransition) {
        myTransition.classList.add(classTransition)
      }

      if (e.answeredMessage) {
        this.$store.commit('answered/setAnsweredMessage', e.answeredMessage)
        this.$router.push({ name: 'Answered' })
      } else if (e.endMessage) {
        const endMessage = []
        endMessage.push(e.endMessage)
        this.$store.commit('end/setEndMessage', endMessage)
        this.$router.push({ name: 'End' })
      } else {
        const newObjQuestion = this.$store.getters['question/getQuestion']
        newObjQuestion.push(e.nextQuestion)
        this.$store.commit('question/setQuestion', newObjQuestion)

        if (myTransition) {
          setTimeout(() => {
            myTransition.classList.remove(classTransition)
          }, 100)
        }

        this.$router.push({
          name: 'Survey',
          params: {
            sending: this.sendingId,
            seeds: this.$route.params.seeds,
            question: e.nextQuestion._id,
            order: parseInt(this.$route.params.order) + 1
          }
        })

        this.returnAnimation()
        this.setCurrentLanguage()
        this.$root.$emit('next-bar-progress', this.$route.params.order - 1)

        setTimeout(() => {
          this.returnScreen()
          this.$root.$emit('hidden-logo-footer', true)
          if (this.myAnimation.name === 'insideBox') {
            this.returnScreen()
          }
          this.loading = false
        }, 100)
      }
    },
    before () {
      const beforeQuestion = this.$store.getters['question/getQuestion']
      beforeQuestion.pop()
      this.$store.commit('question/setQuestion', beforeQuestion)
      const lastQuestion = beforeQuestion.length - 1
      this.$router.push({
        name: 'Survey',
        params: {
          seeds: this.$route.params.seeds,
          question: beforeQuestion[lastQuestion]._id,
          order: this.$route.params.order - 1
        }
      })
      setTimeout(() => {
        this.returnScreen()
        this.$root.$emit('back-bar-progress', this.$route.params.order)
        this.refreshFooter++
      }, 10)
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-survey {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bodyColor);
  .container-question {
    position: relative;
    width: 650px;
    min-height: 650px;
    transition: width 0.6s, min-height 0.6s;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .header-question {
      position: relative;
      width: 100%;
      min-height: 140px;
      padding: 10px 0;
      background: var(--headerBg);
      display: flex;
      justify-content: center;
      .back-question {
        position: absolute;
        top: 15px;
        left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .has-label-button {
          cursor: pointer;
          background: transparent;
          font-weight: bold;
          color: var(--headerColor);
          display: flex;
          align-items: center;
          justify-content: center;
          .label-previous-button {
            font-size: 0.8vw;
            font-weight: bold;
          }
          .icon-header-previous {
            color: var(--headerColor);
            font-size: 20px;
            width: 20px;
            height: 20px;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
      .close-question {
        position: absolute;
        top: 15px;
        right: 5px;
      }
      .titles-question {
        width: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p:first-child {
          margin-bottom: 10px;
        }
        p {
          color: var(--headerColor);
          font-size: 17px;
          text-align: center;
        }
      }
      .icon-header {
        font-size: 20px;
        font-weight: bold;
        color: var(--headerColor);
        background-image: linear-gradient(
          -60deg,
          transparent, transparent 40%,
          #ffffff44 40%, #ffffff44 60%,
          transparent 60%, transparent 100%
        );
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position-x: 150%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .icon-header:hover {
        background-position-x: -150%;
        transition: background-position-x 1s;
        cursor: pointer;
      }
    }
    .dynamic-question {
      width: 100%;
      min-height: 370px;
      background: var(--splashBg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.animation {
  z-index: 50;
}

.closing {
  width: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
}

// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .container-survey {
    padding: 0;
    .container-question {
      width: 100vw;
      border-radius: 0 !important;
      .header-question {
        .titles-question {
          width: 82% !important;
          padding-top: 5px;
          p {
            font-size: 16px !important;
          }
        }
        .has-label-button {
          .icon-header-previous {
            font-size: 1.2rem !important;
          }
          .label-previous-button {
            font-size: 1rem !important;
          }
        }
      }
      .dynamic-question {
        min-height: var(--body-height) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
