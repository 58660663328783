<template>
  <div>
    <div v-if="!displayError" class="content-init-sendings">
      <cicle-t></cicle-t>
      <app-heading level="h5" class="loading">{{$t('loading')}}</app-heading>
    </div>
    <div v-else class="content-not-found">
      <div class="content-init-sendings">
        <img src="/img/404.png" class="setFilterGrey textGraphic">
        <app-heading level="h5">{{$t('something_is_wrong')}}</app-heading>
       </div>
    </div>
  </div>
</template>
<script>
import { surveyService } from '@/_services'
import { responseHelper, translator, validateSending } from '@/_helpers'
export default {
  name: 'Sending',
  data () {
    return {
      displayError: false
    }
  },
  components: {
    'cicle-t': () => import('vue-loading-spinner/src/components/Circle4.vue'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  async mounted () {
    this.$store.commit('survey/startEmptySurvey', '')
    const sendingId = this.$route.params.sendingId
    let short = this.$store.getters['short/getShort']
    if (!short.data) {
      short = await surveyService.short(sendingId)

      if (!short) { return this.$router.push({ name: 'NotFound' }) }

      this.$store.commit('short/setShort', short)
    }

    if (!short.data.survey) return false // prevent code error

    const sendingIsExpired = validateSending.isExpiredAt({ expireInterval: short.data.survey.expireInterval, createdAt: short.data.createdAt })

    if (short.data.isExpired || sendingIsExpired) {
      this.$store.commit('seedData/updateExpiredSurvey', short.data.survey)
      return this.$router.push({ name: 'Expired' })
    }

    const surveyAlreadyResponded = short.data.surveyAlreadyResponded || short.data.doneAt

    if (surveyAlreadyResponded) {
      const answeredMessage = {
        title: short.data.survey.answeredMessage,
        type: 'answered',
        details: short.data.survey.answeredMessageDetails,
        image: short.data.survey.images
      }

      this.$store.commit('answered/setAnsweredMessage', answeredMessage)
      this.$store.commit('survey/survey', short.data.survey)
      this.$store.commit('survey/setSurveyAlreadyResponded', surveyAlreadyResponded)
      return this.$router.push({ name: 'Answered' })
    }

    this.updateSurvey(short)
  },
  methods: {
    async updateSurvey (short) {
      if (short.data) {
        const data = short.data
        const sendingId = this.$route.params.sendingId

        const sendingIdStore = this.$store.getters['seedData/getSendingId']

        if (!sendingIdStore) { this.$store.commit('seedData/setSendingId', sendingId) }

        this.$store.commit('survey/survey', data.survey)
        this.$store.commit('seedData/seed', short.data.seedData)

        // disableEmbeded - parâmetro no short, para saber se pode ou não responder essa pesquisa vinda do embedado do email.

        if (this.$route.query.r && !short.data.disableEmbeded) {
          const firstQuestion = data.survey.questions[0]
          const question = []
          question.push(firstQuestion)
          this.$store.commit('question/setQuestion', question)
          try {
            if (firstQuestion && firstQuestion.language) {
              this.$i18n.locale = translator.getCurrentLocale(firstQuestion.language)
            }
            const formatedResponse = responseHelper.formatWhenIsAnsweredAutomaticWithUrlParam(firstQuestion, sendingId, this.$route.query.r)
            if (firstQuestion.ui === 'singleOther' && formatedResponse.isOther) {
              this.$router.push({
                name: 'Survey',
                params: {
                  sending: sendingId,
                  seeds: short.data.seed,
                  question: firstQuestion._id,
                  order: 1
                },
                query: { r: 'Outros' }
              })
              return
            }
            const waitSomeRandomPeriodToPreventUrlCrawlersDuplicateResponses = responseHelper.getRandomInt(1000, firstQuestion, formatedResponse) // previne respostas duplicadas em perguntas embedadas onde bots acessam as urls do email
            await new Promise(resolve => {
              setTimeout(() => {
                resolve('')
              }, waitSomeRandomPeriodToPreventUrlCrawlersDuplicateResponses)
            })
            // start - add r params to answers list
            const findRatingonQuestionOptions = responseHelper.getOptions(firstQuestion).find(o => {
              return o.rating === this.$route.query.r || o.value === this.$route.query.r || o.label === this.$route.query.r
            })
            this.$store.commit('answersList/setAnswer', { id: firstQuestion.id, value: findRatingonQuestionOptions.label })
            formatedResponse.isEmbed = true
            // end - add r params to answers list
            const res = await surveyService.response(formatedResponse)
            if (res.data && res.data.nextQuestion) {
              const questionAlreadyExists = this.$store.getters['question/getQuestion']
              let newObjQuestion
              // CHEkando se ja existe question do store (possivelmente vinda do embedado)
              questionAlreadyExists.length > 0 ? newObjQuestion = questionAlreadyExists : newObjQuestion = []
              newObjQuestion.push(res.data.nextQuestion)
              this.$store.commit('question/setQuestion', newObjQuestion)
              return this.$router.push({
                name: 'Survey',
                params: {
                  sending: sendingId,
                  seeds: short.data.seed,
                  question: res.data.nextQuestion._id,
                  order: 2
                }
              })
            }
            if (res.data && res.data.endMessage) {
              const endMessage = []
              endMessage.push(res.data.endMessage)
              this.$store.commit('end/setEndMessage', endMessage)
              this.$router.push({ name: 'End' })
            }
          } catch (error) {
            console.log('Erro ao tentar responder automaticamente ', error)
          }
        } else {
          const freshSurvey = this.$store.getters['survey/getSurvey']
          if (freshSurvey && freshSurvey.startMessage && freshSurvey.startMessage.isEnabled) {
          /*
            Se recebemos pela página de Start, ela vai checar se tem startMessage.isEnabled e mandar para Survey;
            Mas quando recebemos aqui na Sending, nós temos que checar se tem startMessage.isEnabled e mandar para lá
            com os parâmetros necessários (seeds, fromSending como true), e ela fará o fluxo antes de dar push para a Survey.
          */
            return this.$router.push({
              name: 'Start',
              params: { seeds: short.data.seed, metaFromSending: short.data.seedData.metadata },
              query: { fromSending: true }
            })
          }
          const nextQuestionOrEndMessage = await surveyService.getFirstQuestion(sendingId, { ...short.data.seedData })
          const data = nextQuestionOrEndMessage.data
          if (data && data.nextQuestion) {
            const firstQuestion = data.nextQuestion
            this.$store.commit('question/setQuestion', [firstQuestion])
            this.$router.push({
              name: 'Survey',
              params: {
                sending: sendingId,
                seeds: short.data.seed,
                question: firstQuestion._id,
                order: 1
              }
            })
          } else if (data && data.endMessage) {
            const endMessage = []
            endMessage.push(nextQuestionOrEndMessage.data.endMessage)
            this.$store.commit('end/setEndMessage', endMessage)
            this.$router.push({ name: 'End' })
          } else if (data.answered) {
            return this.$router.push({ name: 'Answered' })
          } else {
            this.displayError = true
          }
        }
      } else {
        this.displayError = true
      }
    }
  }
}

</script>
<style lang="scss">
.content-not-found {
  .heading{
    display: block;
    margin-top: 20px !important;
    color: #868585 !important;
  }
}
.content-init-sendings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
  .loading {
    margin-top: 10px !important;
  }
}
</style>
