export default {
  redirect: 'Você será redirecionado para',
  expired: 'Esta pesquisa já foi expirada.',
  previousQuestion: 'Anterior',
  vu: 'Muito insatisfeito',
  un: 'Insatisfeito',
  ind: 'Indiferente',
  sat: 'Satisfeito',
  satisf: 'Satisfatório',
  vs: 'Muito satisfeito',
  vb: 'Muito ruim',
  bd: 'Ruim',
  st: 'Bom',
  vg: 'Muito bom',
  ex: 'Excelente',
  le: 'Pouco esforço',
  me: 'Muito esforço',
  ee: 'Esforço esperado',
  op1: 'Sim',
  op2: 'Não',
  op3: 'Não sei',
  op4: 'Talvez',
  op: 'Opção',
  required: 'Preencha os campos obrigatórios',
  other_required: 'Preencha o campo',
  multiple_answer_required: 'Você deve selecionar entre',
  multiple_and: 'e',
  multiple_options: 'opções',
  loading: 'Carregando',
  answered: 'Esta pesquisa já foi respondida',
  something_is_wrong: 'Algo está errado!',
  max_length: 'Máximo de caracteres',
  unsubscribe: {
    title: 'Você realmente deseja cancelar a sua inscrição?',
    yes: 'SIM',
    no: 'NÃO',
    thanks: 'Obrigado!',
    done: 'Você não está mais inscrito, caso queira se inscrever novamente basta entrar em <a href="https://binds.co"><b>contato conosco</b></a>.'
  },
  inativity: {
    are_you_there: 'Você está ai ?',
    redirect_idle: 'Reiniciando pesquisa em:'
  },
  multiple: {
    field: 'campo',
    fields: 'campos',
    min: 'Escolha no mínimo',
    max: 'Escolha no máximo'
  },
  ces: {
    totally_disagree: 'Discordo totalmente',
    disagree: 'Discordo',
    partially_disagree: 'Discordo Parcialmente',
    neutral: 'Neutro',
    partially_agree: 'Concordo Parcialmente',
    agree: 'Concordo',
    totally_agree: 'Concordo totalmente'
  },
  nvs: {
    nvs_op1: 'Significativamente melhor',
    nvs_op2: 'Um pouco melhor',
    nvs_op3: 'Nem melhor, nem pior',
    nvs_op4: 'Um pouco pior',
    nvs_op5: 'Significativamente pior'
  },
  referral: {
    alert_email: 'E-mail inválido',
    alert_phone: 'Telefone inválido'
  },
  error: 'Algo deu errado',
  survey_not_found: 'Pesquisa não encontrada',
  back_question: 'Voltar'
}
