export const getAnimations = {
  returnAnimation
}

function animations (animation, colors, smallLogo) {
  const myAnimations = {
    tilesPage: `
      <div class="loader__tile" style="background: ${colors.footerBg} "></div>
      <div class="loader__tile" style="background: ${colors.footerBg} "></div>
      <div class="loader__tile" style="background: ${colors.footerBg} ">
        <div class="image-animation">
          <img src="${smallLogo}">
        </div>
      </div>
      <div class="loader__tile" style="background: ${colors.footerBg} "></div>
      <div class="loader__tile" style="background: ${colors.footerBg} "></div>
    `
  }

  return myAnimations[animation]
}

function returnAnimation (yourAnimation, survey) {
  const smallLogo = survey.images[1] && survey.images[1].value ? survey.images[1].value : false
  const returnAni = animations(yourAnimation.name, survey.colors, smallLogo)

  return returnAni
}
