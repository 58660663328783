<template>
  <div class="expired">
    <div class="heading-expired">
      <img class="logo" v-if="currentExpiredSurvey.images && currentExpiredSurvey.images[0].value"
        :src="currentExpiredSurvey.images[0].value"
        :alt="currentExpiredSurvey.images[0].alias">
      <app-heading level="h5">{{ $t('expired') }}</app-heading>
        <a class="logo-binds-end" target="_blank" href="https://binds.co/pesquisa/conheca-a-binds-co/">
          <img class="img-binds-logo" src="../assets/binds-end-message-logo.svg" alt="logo-binds">
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expired',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    currentExpiredSurvey () { return this.$store.getters['seedData/getExpiredSurveyData'] }
  }
}
</script>
<style lang="scss">
.expired {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .heading-expired {
    background: #ffffff;
    width: 650px;
    height: 550px;
    border-radius: 10px;
    padding: 10px;
    -webkit-box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    -moz-box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .heading {
      color: #333333;
      text-align: center;
      font-size: 35px;
    }
    h1 {
      font-size: 45px !important;
    }
    .logo {
      max-width: 60%;
      max-height: 40%;
    }
    .small-logo {
      width: 10%;
      height: 8%;
    }
    .logo-binds-end {
      .img-binds-logo {
        width: 65px;
      }
    }
  }
}
</style>
