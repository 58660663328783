import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/translations/i18n/lang/'
const languageInput = 'pt-BR'

// if (localStorage.getItem('bindsLang')) {
//   languageInput = localStorage.getItem('bindsLang')
// }

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: languageInput, // set locale
  fallbackLocale: languageInput,
  messages // set locale messages
})

export default i18n
