const API_URL = process.env.VUE_APP_URL
// const API_URL = 'http://api-v2.binds.co/api/'
// const headers = {
//   'Authorization': () => 'Binds.co ' + JSON.parse(localStorage.getItem('user')).token,
//   'Accept': 'application/json, text/plain, */*',
//   'cache-control': 'no-cache',
//   'Content-Type': 'application/json'
// }
const axios = require('axios')

export const surveyService = {
  getFirstQuestion,
  sendingOptedOut,
  getSeeds,
  response,
  sendings,
  short,
  done
}

// function insertToken () {
//   return 'Binds.co ' + JSON.parse(localStorage.getItem('user')).token
// }

async function getSeeds (id) {
  try {
    const result = await axios.get(API_URL + 'surveyLinks/' + id)
    return result
  } catch (error) {
    console.log('error', error)
  }
}

async function getFirstQuestion (sendingId, body) {
  const result = await axios
    .post(API_URL + 'sendings/' + sendingId + '/first-survey-question', body).catch(error => {
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          return { data: { answered: true } }
        }
        throw new Error(error.response.data.message)
      }
    })
  return result
}

async function sendingOptedOut (id) {
  const result = await axios
    .get(API_URL + 'sendings/' + id + '/optout?confirmed=true')
  return result
}

async function sendings (id, body) {
  const result = await axios
    .post(API_URL + 'surveyLinks/' + id + '/sendings', body)
  return result
}

async function done (id) {
  const result = await axios
    .get(API_URL + 'sendings/' + id + '/done')
  return result
}

async function response (body) {
  const res = await axios
    .post(API_URL + 'surveyResponses/', body)
  return res
}

async function short (id) {
  try {
    const result = await axios.get(API_URL + 'sendings/' + id + '/short')
    return result
  } catch (error) {
    console.log('error', error)
  }
}
