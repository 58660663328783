const Keycode = {
  em: {
    init: '*|q-',
    end: '|*',
    rxstart: '\\*\\|q\\:',
    rxmid: '(.*?)',
    rxend: '\\|\\*'
  },
  span: {
    init: '*|',
    end: '|*',
    rxstart: '\\*\\|',
    rxmid: '(.*?)',
    rxend: '\\|\\*'
  },
  b: {
    init: '**',
    end: '**',
    rxstart: '\\*{2}',
    rxmid: '(.*?)',
    rxend: '\\*{2}'
  },
  i: {
    init: '*',
    end: '*',
    rxstart: '\\*',
    rxmid: '(.*?)',
    rxend: '\\*'
  },
  s: {
    init: '~~',
    end: '~~',
    rxstart: '\\~{2}',
    rxmid: '(.*?)',
    rxend: '\\~{2}'
  },
  u: {
    init: '__',
    end: '__',
    rxstart: '\\_{2}',
    rxmid: '(.*?)',
    rxend: '\\_{2}'
  }
}

const replaceMetadata = (str, seedDataOrShort, answersList) => {
  if (!str) {
    return ''
  }
  let newString = ''
  if (Object.keys(seedDataOrShort).length > 0) {
    newString = str.replace(/\*\|((?:(?!\|\*).)+)\|\*/g, (match, f) => {
      return seedDataOrShort[f] || match
    })
  }
  const stripTags = function (string, allowed) {
    return string.replace(/<(?:.)*?>/gim, '')
  }
  let nhtml = stripTags(newString || str).replace(/\n/gi, '<br>')
  Object.keys(Keycode || {}).forEach(function (key) {
    var regex = new RegExp(
      [Keycode[key].rxstart, Keycode[key].rxmid, Keycode[key].rxend].join(''),
      'gmi'
    )
    nhtml = nhtml.replace(regex, function (word) {
      if (key === 'br') {
        return word.replace(
          new RegExp(Keycode[key].rxstart + '' + Keycode[key].rxend, 'gim'),
          ['<', key, '>'].join('')
        )
      } else if (key === 'em') {
        const questionId = word.replace(new RegExp([Keycode[key].rxstart, Keycode[key].rxend].join('|'), 'gi'), '')
        const response = answersList.find(item => item.id === questionId)
        if (response) {
          return response.value
        }
      } else {
        var tag = word
          .replace(new RegExp(Keycode[key].rxstart), ['<', key, '>'].join(''))
          .replace(new RegExp(Keycode[key].rxend), ['</', key, '>'].join(''))
        return tag
      }
    })
  })
  return nhtml || str
}

export { replaceMetadata }
