export default {
  redirect: 'You will be redirected to',
  expired: 'This survey has already expired.',
  previousQuestion: 'Previous',
  vu: 'Very unsatisfied',
  un: 'Unsatisfied',
  ind: 'Indifferent',
  sat: 'Satisfied',
  satisf: 'Satisfying',
  vs: 'Very satisfied',
  vb: 'Very bad',
  bd: 'Bad',
  st: 'Good',
  vg: 'Very good',
  ex: 'Excellent',
  le: 'Little effort',
  me: 'Much effort',
  ee: 'Expected effort',
  op1: 'Yes',
  op2: 'No',
  op3: "Don't know",
  op4: 'Maybe',
  op: 'Option',
  required: 'Fill in the required fields',
  other_required: 'Fill in the field',
  multiple_answer_required: 'You must select between',
  multiple_and: 'and',
  multiple_options: 'options',
  loading: 'Loading',
  answered: 'This survey has already been answered',
  something_is_wrong: 'Something is wrong!',
  max_length: 'Maximum characters',
  unsubscribe: {
    title: 'Do you really want to unsubscribe?',
    yes: 'YES',
    no: 'NO',
    thanks: 'Thanks!',
    done: 'You are no longer subscribed, if you want to subscribe again just enter <a href="https://binds.co"><b>contact us</b></a>.'
  },
  inativity: {
    are_you_there: 'Are you there ?',
    redirect_idle: 'Restarting search in:'
  },
  multiple: {
    field: 'field',
    fields: 'fields',
    min: 'Choose at least',
    max: 'Choose maximum'
  },
  ces: {
    totally_disagree: 'I Totally disagree',
    disagree: 'I Disagre',
    partially_disagree: 'Partially Disagree',
    neutral: 'Neutral',
    partially_agree: 'Partially agree',
    agree: 'I agree',
    totally_agree: 'I Totally agree'
  },
  nvs: {
    nvs_op1: 'Significantly better',
    nvs_op2: 'A little better',
    nvs_op3: 'Neither better nor worse',
    nvs_op4: 'A little worse',
    nvs_op5: 'Significantly worse'
  },
  referral: {
    alert_email: 'Invalid email',
    alert_phone: 'Invalid phone'
  },
  error: 'Something went wrong',
  survey_not_found: 'Survey not found',
  back_question: 'Back'
}
