import i18n from '../../translations/i18n'

const state = {
  answeredMessage: {
    type: 'answered',
    title: i18n.t('answered'),
    details: {},
    image: ''
  }
}

const mutations = {
  setAnsweredMessage (state, payload) {
    state.answeredMessage = payload
  }
}

const getters = {
  getAnsweredMessage: state => state.answeredMessage
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
