const API_URL = process.env.VUE_APP_URL
const axios = require('axios')

export const previewService = {
  surveyPreview
}

async function surveyPreview (id, body) {
  const previewUrl = `${API_URL}surveys/${id}/preview-survey-flow`
  const res = await axios
    .post(previewUrl, body)
  return res
}
