<template>
  <div class="container-start" :style="{'min-height': isMobile, '--header': headerHeight, '--body-height': dynamicHeight }">
    <div class="content-start" :style="{'min-height': isMobile}">
      <default-loading v-if="loading" :showIcon="true" :showLoad="true" :colors="colors"/>
      <div class="header-start">
        <div class="title-start">
          <p v-if="titleConfig && !titleInputConfig.hiddenTitle" v-html="replaceMeta(titleInputConfig.value)"></p>
        </div>
      </div>
      <div class="body-start">
        <div class="image-start">
          <img v-if="!logoInputConfig.isHidden" :src="image" alt="">
        </div>
        <div class="fields-start" v-if="isAutomation">
          <div
            class="field"
            :style="{ display: !field.showThisField ? 'none': 'block' }"
            v-for="(field, idx) in fields" :key="idx">
            <p v-if="field.label" class="title-field">{{ field.label }}</p>
            <input class="input-text-field" v-if="!field.options" type="text" v-model="field.value" :placeholder="field.placeholder">
            <v-select
              :placeholder="field.placeholder"
              v-if="field.options && field.options.length > 0"
              class="dynamicSelector"
              v-model="field.value"
              label="name"
              :reduce="name => name.value"
              :options="field.options"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="footer-start">
        <button class="start-survey" :style="{ 'position': fields.length > 0 && isMobile ? 'static' : !isMobile ? 'flex' : 'fixed' }" @click="start()">{{ survey.nextLabel }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { surveyService } from '@/_services'
import { replaceMetadata } from '../_helpers/metadata'
import DefaultLoading from '../components/messages/DefaultLoading.vue'

export default {
  name: 'Start',
  props: ['question', 'response'],
  components: {
    vSelect: () => import('vue-select'),
    DefaultLoading
  },
  data () {
    return {
      loading: false,
      isMobile: '',
      headerHeight: '',
      dynamicHeight: '',
      showStart: false,
      survey: '',
      image: '',
      sendingId: '',
      firstQuestion: {},
      started: false,
      titleInputConfig: {},
      logoInputConfig: {},
      titleConfig: false,
      isAutomation: false,
      fields: [],
      metadata: [],
      metadatasToReplace: {},
      canShowFirstQuestionDirect: true
    }
  },
  async mounted () {
    this.survey = this.$store.getters['survey/getSurvey']
    this.returnScreen()
    // checa se está sendo redirecionado da página Sending.vue, para começar a pesquisa.
    if (!this.$route.query.fromSending) {
      this.$store.commit('answersList/clearAnswers')
      this.$store.commit('survey/startEmptySurvey', '')
      const seeds = await surveyService.getSeeds(this.$route.params.seeds)

      if (!seeds) { return this.$router.push({ name: 'NotFound' }) }

      this.survey = seeds.data
    } else {
      // se estiver vindo da página Sending.vue e tiver metadados no short, vai receber por aqui.
      const short = this.$store.getters['short/getShort']
      if (short && short.data.seedData.metadata) {
        this.metadatasToReplace = short.data.seedData.metadata
      }
    }
    if (this.survey && this.survey.isExpired) {
      // correção de expired de acesso por link
      this.$store.commit('seedData/updateExpiredSurvey', this.survey)
      return this.$router.push({ name: 'Expired' })
    }
    this.image = this.survey.images && this.survey.images.length > 0 ? this.survey.images[0].value : ''
    this.$store.commit('survey/survey', this.survey)
    // hiddenTitle
    this.titleInputConfig = this.survey.startMessage.title
    // logo
    this.logoInputConfig = this.survey.startMessage.logo
    // initial message
    this.titleConfig = this.survey.startMessage.isEnabled
    // link params
    this.$store.commit('survey/survey', { closeSurvey: this.$route.query.close })
    this.$store.commit('survey/survey', { loop: this.$route.query.loop })
    this.$store.commit('account/account', this.survey.account)
    this.$store.commit('survey/survey', { initialRoute: { url: this.$route.fullPath, query: this.$route.query, params: this.$route.params } })

    // store answered message
    const answeredMessage = {
      title: this.survey.answeredMessage,
      type: 'answered',
      details: this.survey.answeredMessageDetails,
      image: this.survey.images
    }
    // automação, com url dinamica
    // a aplicação só vai parar para pedir algum valor caso tenha showThisField como true para algum field
    // caso contrário ela vai tentar seguir o fluxo
    if (this.$route.query.fields) {
      this.isAutomation = true
      try {
        if (this.$route.query.metadata) {
          const metas = this.formatUrlStringParam(this.$route.query.metadata)
          if (typeof metas === 'object') {
            metas.forEach(meta => {
              if (typeof meta === 'object' && Object.keys(meta)[0]) {
                const key = Object.keys(meta)[0]
                this.metadatasToReplace[key] = meta[key]
              }
              this.metadata.push(meta)
            })
          }
        }
        const fields = this.formatUrlStringParam(this.$route.query.fields)
        if (typeof fields === 'object') {
          fields.forEach(field => {
            this.metadatasToReplace[field.type] = field.value
            if (field.showThisField) {
              this.canShowFirstQuestionDirect = false
              this.showStart = true
            }
            this.fields.push(field)
          })
          if (fields.length === 0) {
            this.canShowFirstQuestionDirect = false
          }
          if (this.canShowFirstQuestionDirect) {
            this.start()
          }
        }
      } catch (error) {
        console.log('error', error)
        return false
      }
    } else {
      this.showStart = true
    }
    this.$store.commit('answered/setAnsweredMessage', answeredMessage)
  },
  computed: {
    colors () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.colors
    },
    mountedSurvey () { return this.$store.getters['survey/getSurvey'] }
  },
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      const headerHeight = document.querySelector('.header-start').clientHeight
      if (screenWidth < 850) {
        this.headerHeight = `${headerHeight}px`
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${(screenHeight - headerHeight) - 100}px`
      }
    },
    replaceMeta (str) {
      const formatObject = obj => {
        const formattedObj = {}
        for (const key in obj) {
          if (key === 'name' || key === 'email' || key === 'phone') formattedObj['_' + key] = obj[key]
          else formattedObj[key] = obj[key]
        }
        return formattedObj
      }

      return replaceMetadata(str, formatObject(this.metadatasToReplace), this.answersList)
    },
    formatUrlStringParam (params) {
      return typeof params === 'string' ? JSON.parse(params) : params
    },
    async start () {
      this.loading = true
      // se for type diferente de name/email/phone será enviado como metadado junto com seus valores
      // para enviar um metadado como field deve colocar a key do metadado na propriedade type: {type: 'nome do metadado', value: 'aqui pode já vir com algo ou deixar que a pessoa preencha'}
      // para enviar um metadado diretamente, basta enviar ele como um parametro: {urlDaPesquisa....}&metadata=[{"Consultor": "Bruno"}])
      // para considerar exigir um field, basta falar que ele é required
      // fields=[{ "type": "name", "placeholder": "Insira seu nome", "showThisField": true, "required": true, "value": "" }, { "type": "email", "showThisField": true, "value": "bruno@binds.co" }, { "type": "phone", "placeholder": "Insira seu telefone", "showThisField": true }]&metadata=[{"Consultor": "Bruno"}]
      this.started = true
      const seedData = {
        from: {
          name: '',
          email: '',
          phone: ''
        },
        metadata: {}
      }
      if (this.isAutomation) {
        let canContinue = true
        if (this.metadata.length > 0) {
          this.metadata.forEach(meta => {
            if (typeof meta === 'object' && Object.keys(meta)[0]) {
              const key = Object.keys(meta)[0]
              seedData.metadata[key] = meta[key]
            }
          })
        }
        this.fields.forEach(field => {
          if (field.required && field.value.length <= 0) {
            canContinue = false
          }
          if (seedData.from[field.type] !== undefined) {
            seedData.from[field.type] = field.value
          } else {
            seedData.metadata[field.type] = field.value
          }
        })

        if (!this.canShowFirstQuestionDirect && !canContinue) {
          // como n da para saber o idioma da msg de alerta, então tentamos pegar da primeira questão
          if (this.survey.questions[0] && this.survey.questions[0].language) {
            this.$i18n.locale = this.survey.questions[0].language
          }
          this.loading = false
          return this.$store.commit('alerts/alert', { showAlert: true, message: this.$t('required') })
        }
        this.$store.commit('seedData/seed', seedData)
      }

      this.sendingId = this.$store.getters['seedData/getSendingId']
      if (!this.$route.query.fromSending) {
        const sendings = await surveyService.sendings(this.$route.params.seeds, { seedData })
        this.sendingId = sendings.data._id
        this.$store.commit('seedData/seed', sendings.data.seedData)
        this.$store.commit('seedData/setSendingId', this.sendingId)
      }

      // se tiver first question eu atualizo o store
      // se tiver endmessage, atualizo o store e faço redirect
      try {
        const nextQuestionOrEndMessage = await surveyService.getFirstQuestion(this.sendingId, { ...seedData })
        const data = nextQuestionOrEndMessage.data

        if (data.answered) {
          throw new Error('Survey already responded')
        }

        if (data && data.nextQuestion) {
          this.$i18n.locale = data.nextQuestion.language
          const firstQuestion = data.nextQuestion
          this.$store.commit('question/setQuestion', [firstQuestion])
          const firstResponseLink = this.$route.query.r
          const params = {
            sending: this.sendingId,
            seeds: this.$route.params.seeds,
            question: firstQuestion._id,
            order: 1
          }
          if (firstResponseLink) {
            return this.$router.push({ name: 'Survey', params, query: { r: firstResponseLink, isLink: true } })
          }
          return this.$router.push({ name: 'Survey', params })
        } else if (data && data.endMessage) {
          const endMessage = []
          endMessage.push(nextQuestionOrEndMessage.data.endMessage)
          this.$store.commit('end/setEndMessage', endMessage)
          this.$router.push({ name: 'End' })
        }
      } catch (error) {
        return this.$router.push({ name: 'Answered' })
      }
    }
  }
}
</script>

<style lang="scss">
.container-start {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bodyColor);
  overflow-y: auto;
  .content-start {
    position: relative;
    background: white;
    width: 650px;
    min-height: 650px;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .header-start {
      width: 100%;
      min-height: 140px;
      padding: 10px 0;
      background: var(--headerBg);
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: center;
      .title-start {
        width: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p {
          margin-bottom: 10px;
          color: var(--headerColor);
          font-size: 1.3rem;
          text-align: center;
        }
      }
    }
    .body-start {
      background: var(--splashBg);
      width: 100%;
      min-height: 410px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image-start {
        width: 100%;
        min-height: 200px;
        max-height: 410px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 385px;
          max-height: 300px;
        }
      }
      .fields-start {
        width: 70%;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .field {
          width: 100%;
          margin-bottom: 10px;
          .title-field {
            font-size: 15px;
            color: var(--splashColor);
            margin-bottom: 5px;
          }
          .input-text-field {
            width: 100%;
            height: 35px;
            outline: none;
            padding-left: 5px;
            font-size: 15px;
            color: #000000;
            border: 1px solid #cacaca;
            border-radius: 3px;
          }
          .input-text-field::placeholder {
            color: #959595;
          }
          .dynamicSelector {
            .vs__dropdown-toggle {
              border-radius: 3px;
              padding: 0;
              .vs__selected-options {
                .vs__selected {
                  color: var(--splashColor);
                  font-size: 16px;
                  margin: 0;
                }
                .vs__search {
                  padding-left: 3px;
                  margin: 0;
                  height: 35px;
                  font-size: 16px;
                }
                .vs__search::placeholder {
                  color: #959595;
                }
              }
            }
            .vs__actions {
              .vs__clear {
                display: none;
              }
            }
            .vs__dropdown-menu {
              max-height: 100px !important;
            }
          }
        }
      }
    }
    .footer-start {
      width: 100%;
      height: 100px;
      position: relative;
      background: var(--splashBg);
      border-radius: 0 0 10px 10px;
      .start-survey {
        border: none;
        position: absolute;
        right: 20px;
        top: 30px;
        padding: 10px 15px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        box-shadow: 0 8px 10px 0 rgba(255, 255, 255, 0.030);
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--buttonBg);
        color: var(--buttonColor);
        background-image: linear-gradient(
          -60deg,
          transparent, transparent 40%,
          #ffffff44 40%, #ffffff44 60%,
          transparent 60%, transparent 100%
        );
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position-x: 150%;
      }
      .start-survey:hover {
        background-position-x: -150%;
        transition: background-position-x 1s;
        cursor: pointer;
      }
    }
  }
}

// responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-start {
    position: relative;
    width: 100vw !important;
    border-radius: 0 !important;
    .header-start {
      border-radius: 0px !important;
      .title-start {
        padding: 0 5px;
        width: 100% !important;
        p {
          line-height: initial;
        }
      }
    }
    .body-start {
      min-height: var(--body-height) !important;
      justify-content: center !important;
      .image-start {
        width: 85% !important;
        height: 100% !important;
        margin: 10px 0;
        align-items: center !important;
        img {
          max-width: 95% !important;
          max-height: 275px !important;
        }
      }
      .fields-start {
        width: 95% !important;
      }
    }
    .footer-start {
      border-radius: 0px !important;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .start-survey {
        top: 80vh !important;
      }
    }
    .vs__dropdown-menu {
      max-height: 115px !important;
      font-size: 14px !important;
    }
  }
}
</style>
