const state = emptyAccount()

function emptyAccount () {
  return {
    account: {
      status: 'ok',
      content: {
        id: 1,
        name: 'binds',
        alias: null,
        url: null,
        logo: null,
        sendingIntervalTime: null,
        sendingIntervalUnit: null,
        hasConfirmedEmail: 1,
        hasInvoice: 1,
        createdAt: '2019-08-14T14:22:18.000Z',
        updatedAt: '2019-08-14T14:22:18.000Z',
        deletedAt: null,
        version: 0
      }
    }
  }
}
const mutations = {
  account (state, payload) {
    state.account = payload
  },
  clearAccountState (state, params) {
    state = emptyAccount()
  }
}

const getters = {
  getAccount: state => state.account.content

}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
