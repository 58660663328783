<template>
  <div class="progress-bar">
    <div class="bar" :class="`idx-${idx + 1}`" v-for="(bar, idx) in total" :key="idx" :style="{'width': widthMiniBar }"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['total', 'colors'],
  components: {},
  data () {
    return {
      widthMiniBar: '',
      answeredQuestions: []
    }
  },
  mounted () {
    const widthBar = (document.querySelector('.progress-bar').clientWidth / this.total) - 3
    this.widthMiniBar = `${widthBar}px`

    for (let i = 0; i < parseInt(this.$route.params.order - 1); i++) {
      this.answeredQuestions.push(i)
    }

    this.answeredQuestions.forEach(order => {
      const bar = document.querySelector(`.idx-${order + 1}`)
      bar.style.background = `${this.colors.headerColor}bf`
    })

    this.$root.$on('next-bar-progress', payload => {
      this.answeredQuestions.push(payload)
      this.answeredQuestions.forEach(order => {
        if (order === payload) {
          const bar = document.querySelector(`.idx-${order}`)
          bar.style.background = `${this.colors.headerColor}bf`
        }
      })
    })

    this.$root.$on('back-bar-progress', payload => {
      this.answeredQuestions.splice(payload - 1, 1)
      const bar = document.querySelector(`.idx-${payload}`)
      bar.style.background = 'transparent'
    })
  }
}
</script>

<style lang="scss">
.progress-bar {
  position: absolute;
  top: 0;
  width: 98%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .bar {
    height: 60%;
    border-radius: 1px;
  }
}
</style>
