<template>
  <div class="overflowPage" style="height: auto; margin-top: 28vh">
    <div class="loading-content" v-if="isLoading">
      <Stretch></Stretch>
    </div>
    <div v-if="!accountDontNeedConfirmOptOut && !optout && !optedNo" id="titles-container">
      <p>{{ $t("unsubscribe.title") }}</p>
    </div>
    <div id="ask-container" class="animated fadeIn">
      <p class="animated fadeIn" v-if="optout && !optedNo" v-html="$t('unsubscribe.done')"></p>
      <p class="animated fadeIn" v-if="optedNo">{{$t('unsubscribe.thanks')}}</p>
      <div id="buttons" v-if="!accountDontNeedConfirmOptOut && $route.params.id && !optout && !optedNo">
        <div @click="confirm()" class="btn yes">
          {{ $t("unsubscribe.yes") }}
        </div>
        <div class="vertical-bar"></div>
        <div class="btn no" @click="optedNo = !optedNo">{{ $t("unsubscribe.no") }}</div>
      </div>
    </div>
    <img
      id="binds-icon-white"
      title="binds.co"
      aria-label="binds.co"
      src="/favicon.ico"
    />
  </div>
</template>

<script>
import { surveyService } from '@/_services'

export default {
  name: 'Unsubscribe',
  components: {
    Stretch: () => import('vue-loading-spinner/src/components/Circle.vue')
  },
  data () {
    return {
      isLoading: false,
      optout: false,
      optedNo: false,
      accountDontNeedConfirmOptOut: false
    }
  },
  beforeDestroy () {
    document.body.classList.remove('overflowPage')
    document.documentElement.classList.remove('overflowPage')
    document.getElementById('app').classList.remove('overflowPage')
  },
  mounted () {
    this.accountDontNeedConfirmOptOut = this.$route.query.needConfirm === 'false'
    if (['pt-BR', 'en-US', 'es-CL'].indexOf(navigator.language) > -1) {
      this.$i18n.locale = navigator.language
    }
    document.body.classList.add('overflowPage')
    document.documentElement.classList.add('overflowPage')
    document.getElementById('app').classList.add('overflowPage')
    if (this.accountDontNeedConfirmOptOut) {
      this.confirm()
    }
  },
  methods: {
    async confirm () {
      this.isLoading = true
      await surveyService.sendingOptedOut(this.$route.params.id)
      this.optout = true
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss">
.overflowPage {
  background: white;
  color: black;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-weight: lighter;
  height: 100vh;
  overflow: hidden;
  a:link {
    text-decoration: none;
    color: black;
  }

  .loading-content {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    z-index: 100;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  a:visited {
    text-decoration: none;
    color: black;
  }
  #binds-icon-white {
    position: absolute;
    right: 1vh;
    width: 4vh;
    bottom: 1vh;
  }
  #ask-container {
    text-align: center;
    padding: 3vh;
    width: 45vh;
    margin: 0px auto;
    position: relative;
    z-index: 1;
    #buttons {
      width: 12vh;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .btn {
        cursor: pointer;
        font-size: 5vh;
        padding: 1vh;
        border-radius: 0.8vh;
      }
      .no {
        border: 0.1vh solid black;
        color: black;
      }
      .yes {
        background: black;
        color: white;
      }
      .vertical-bar {
        border-left: 0.2vh solid black;
        margin: 1vh;
      }
    }
    p {
      margin: 2.6vh auto;
      font-size: 3.1vh;
      display: block;
    }
  }
  #titles-container {
    text-align: center;
    padding: 2vh;
    position: relative;
    z-index: 2;
    :nth-child(1) {
      font-size: 5vh;
      font-weight: bold;
    }
  }
  #background-cate {
    width: 100vw;
    position: absolute;
    left: 0px;
    z-index: 0;
    top: 0;
    opacity: 0.2;
    height: 50vw;
  }
  .unsub-cate {
    width: 50vh;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
}
</style>
