<template>
  <div class="container-survey-prev" :style="{'min-height': isMobile, '--header-and-footer': headerHeight, '--body-height': dynamicHeight, 'background-color': mountedSurvey.colors.bodyColor }">
    <div class="container-question-prev" :style="{'min-height': isMobile}" v-show="!starting">
      <div class="animation-prev active" v-html="myAnimation.html"></div>
      <default-loading v-if="loading" :showIcon="showIcon" :showLoad="showLoad" :colors="mountedSurvey.colors"/>
      <div class="header-question" :style="{'background-color': mountedSurvey.colors.headerBg, 'color': mountedSurvey.colors.headerColor}">
        <progress-bar :total="mountedSurvey.questions.length" :colors="mountedSurvey.colors"/>
        <div class="back-question" v-if="questionsList.length > 0 && !mountedSurvey.hidePreviousButton">
          <i class="material-icons icon-header" @click="before()">arrow_back_ios</i>
        </div>
        <div class="close-question">
          <i class="material-icons icon-header" v-if="mountedSurvey.closeSurveyPreview" @click="closeSurveyPreview()">close</i>
        </div>
        <div class="titles-question">
          <p v-if="mountedQuestion.label" v-html="replaceMeta(mountedQuestion.label)"></p>
          <p v-if="mountedQuestion.question" v-html="replaceMeta(mountedQuestion.question)"></p>
        </div>
      </div>
      <div
        class="dynamic-question-prev"
        :style="{ 'background-color': mountedSurvey.colors.splashBg }"
        :is="helperAnswer()"
        :key="mountedQuestion._id"
        :question="mountedQuestion"
        :colors="mountedSurvey.colors || {}"
        @update-question="updateQuestionPreview"
        @loading-question="loadingQuestion"
        @question-answered="next"
        @value-changed="valueChanged">
      </div>
      <content-footer :question="mountedQuestion" :survey="mountedSurvey" :key="refreshFooter" @footer-submit="response('')"></content-footer>
    </div>
    <idle />
  </div>
</template>
<script>
import { surveyService, previewService } from '@/_services'
import { responseHelper, translator, getAnimations } from '@/_helpers'
import { replaceMetadata } from '../_helpers/metadata'
import '../sass/animations/tilesPage.scss'
import createActivityDetector from 'activity-detector'
import DefaultLoading from '../components/messages/DefaultLoading.vue'
import ProgressBar from '../components/messages/ProgressBar.vue'

export default {
  name: 'Preview',
  components: {
    app10num: () => import('@/components/responseUi/10num.vue'),
    appblank: () => import('@/components/responseUi/blank.vue'),
    app5num: () => import('@/components/responseUi/5num.vue'),
    appyn: () => import('@/components/responseUi/yn.vue'),
    appynd: () => import('@/components/responseUi/ynd.vue'),
    appynm: () => import('@/components/responseUi/ynm.vue'),
    app5emo: () => import('@/components/responseUi/5emo.vue'),
    apptext: () => import('@/components/responseUi/text.vue'),
    appmultiple: () => import('@/components/responseUi/multiple.vue'),
    appsingle: () => import('@/components/responseUi/single.vue'),
    appreferral: () => import('@/components/responseUi/referral.vue'),
    appreferraldynamic: () => import('@/components/responseUi/referraldynamic.vue'),
    app1to7: () => import('@/components/responseUi/1to7.vue'),
    app1to7button: () => import('@/components/responseUi/1to7button.vue'),
    app0to10: () => import('@/components/responseUi/0to10.vue'),
    appmatrix1to5: () => import('@/components/responseUi/matrix1to5.vue'),
    appmatrix10num: () => import('@/components/responseUi/matrix10num.vue'),
    applike: () => import('@/components/responseUi/matrixlike.vue'),
    app5radio: () => import('@/components/responseUi/5radio.vue'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'content-footer': () => import('@/components/responseUi/footer.vue'),
    idle: () => import('@/components/alerts/Idle.vue'),
    themestyle: () => import('@/components/theme/ThemeStyle.vue'),
    Stretch: () => import('vue-loading-spinner/src/components/Circle.vue'),
    DefaultLoading,
    ProgressBar
  },
  data () {
    return {
      showIcon: true,
      showLoad: true,
      refreshFooter: 0,
      loading: false,
      isMobile: '',
      headerHeight: '',
      dynamicHeight: '',
      isIdle: null,
      myAnimation: {
        show: false,
        name: '',
        html: ''
      },
      starting: false,
      sendingId: '',
      currentValue: { rating: null },
      display: true,
      emptySeedData: {
        from: {
          name: '',
          email: '',
          phone: ''
        },
        metadata: {}
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('refresh-component')
    this.$root.$off('is-idle')
    this.$root.$emit('stop-idle')
  },
  computed: {
    mountedSurvey () {
      return this.$store.getters['preview/getSurvey']
    },
    mountedQuestion () {
      return this.$store.getters['preview/getCurrentQuestion']
    },
    questionsList () {
      return this.$store.getters['preview/getPreviousResponses']
    },
    answersList () {
      return this.$store.getters['answersList/getAnswersList']
    },
    seedData () {
      return this.$store.getters['preview/getSeedData']
    }
  },
  async mounted () {
    this.returnScreen()
    this.starting = true
    this.$root.$on('refresh-footer', payload => {
      this.refreshFooter++
    })

    this.$store.commit('preview/setPreviewFlag', true)
    // limpando store
    this.$store.commit('preview/clearPreviousList')
    this.$store.commit('answersList/clearAnswers')
    // limpando store - END

    const previewHashId = this.$route.params.id
    const previewShort = await surveyService.getSeeds(previewHashId)
    this.$store.commit('preview/setSurvey', previewShort.data)

    const fields = this.$route.query.fields
    const metadata = fields ? this.formatMetadatasToPost(JSON.parse(this.$route.query.fields)) : this.emptySeedData
    this.$store.commit('preview/setSeedData', metadata)

    const payloadToGetFirstQuestion = {
      seedData: metadata,
      response: {},
      previousResponses: []
    }
    const getFirstQuestion = await previewService.surveyPreview(previewShort.data._id, payloadToGetFirstQuestion)
    if (getFirstQuestion) {
      this.$store.commit('preview/setCurrentQuestion', getFirstQuestion.data.nextQuestion)
    }

    // Verificação de usuário inativo na survey - START
    if (this.mountedSurvey.initialRoute && this.mountedSurvey.initialRoute.query.timeout > 0) {
      const timeIdle = this.mountedSurvey.initialRoute.query.timeout * 1000
      const activityDetector = createActivityDetector({
        timeToIdle: timeIdle,
        ignoredEventsWhenIdle: []
      })

      if (timeIdle > 0) {
        activityDetector.on('idle', () => {
          this.$root.$emit('is-idle', { show: true })
        })
        activityDetector.on('active', () => {
          this.$root.$emit('is-idle', { show: false })
        })
      }
      this.$root.$on('stop-idle', () => {
        activityDetector.stop()
      })
    }
    // Verificação de usuário inativo na survey - END

    this.setCurrentLanguage()
    this.returnAnimation()

    this.$root.$on('refresh-component', () => {
      this.display = false
      setTimeout(() => {
        this.display = true
      }, 100)
    })
    this.starting = false
  },
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      const headerHeight = document.querySelector('.header-question').clientHeight
      if (screenWidth < 500) {
        this.headerHeight = `${headerHeight + 140}px`
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${screenHeight - (headerHeight + 140)}px`
      }
    },
    formatMetadatasToPost (fields) {
      const seedData = {
        from: {
          name: '',
          email: '',
          phone: ''
        },
        metadata: {}
      }

      fields.forEach(field => {
        if (['name', 'email', 'phone'].indexOf(field.type) >= 0) {
          seedData.from[field.type] = field.value
        } else {
          seedData.metadata[field.type] = field.value
        }
      })

      return seedData
    },
    returnAnimation () {
      let myTransition
      if (this.mountedSurvey.animation) {
        if (this.mountedSurvey.animation.isActive) {
          if (this.mountedSurvey.animation.name === 'insideBox') {
            this.myAnimation.show = true
            this.myAnimation.name = 'insideBox'
            myTransition = document.querySelector('.container-survey-prev')
            const first = 'closing'

            if (myTransition.classList.contains(first)) {
              setTimeout(() => {
                myTransition.classList.remove(first)
              }, 100)
            }
          }

          if (this.mountedSurvey.animation.name === 'tilesPage') {
            this.myAnimation.show = true
            this.myAnimation.name = 'tilesPage'
            this.myAnimation.html = getAnimations.returnAnimation(this.myAnimation, this.mountedSurvey)
            const animation = document.querySelector('.animation-prev')
            if (this.mountedSurvey.animation.isActive) {
              setTimeout(() => {
                animation.classList.remove('active')
              }, 300)
            }
          }
        }
        return false
      }
      return false
    },
    setCurrentLanguage () {
      this.$i18n.locale = translator.getCurrentLocale(this.mountedQuestion.language || 'pt-BR')
    },
    replaceMeta (str) {
      const metadata = this.seedData && this.seedData.metadata ? this.seedData.metadata : {}
      return replaceMetadata(str, metadata, this.answersList)
    },
    valueChanged (e) {
      this.currentValue = e
    },
    helperAnswer () {
      if (this.mountedQuestion.ui) {
        let appUi = 'app' + this.mountedQuestion.ui.toLowerCase()
        if (this.mountedQuestion.type === 'matrix' && this.mountedQuestion.ui === '10num') {
          appUi = 'appmatrix10num'
        }
        if (this.mountedQuestion.ui === 'multipleOther') {
          appUi = 'appmultiple'
        }
        if (this.mountedQuestion.ui === 'singleOther') {
          appUi = 'appsingle'
        }
        if (this.mountedQuestion.type === 'enum' && this.mountedQuestion.ui === '10num') {
          appUi = 'app0to10'
        }
        return appUi
      }
    },
    loadingQuestion (e) {
      this.loading = e
    },
    async next () {
      this.loading = true
      if (this.myAnimation.show) {
        this.showIcon = false
        this.showLoad = false
      }

      if (this.myAnimation.show && this.myAnimation.name === 'insideBox') {
        this.showIcon = false
        this.showLoad = true
      }

      let myTransition
      if (!myTransition) this.loading = true
      let classTransition
      if (this.myAnimation.show) {
        if (this.myAnimation.name === 'insideBox') {
          myTransition = document.querySelector('.content-survey')
          classTransition = 'closing'
        }

        if (this.myAnimation.name === 'tilesPage') {
          myTransition = document.querySelector('.animation')
          classTransition = 'active'
        }
      }

      let res
      let body = { question: this.mountedQuestion._id, sending: this.sendingId }
      body = Object.assign(body, this.currentValue)
      const previousResponses = this.$store.getters['preview/getPreviousResponses']
      const response = {
        seedData: this.seedData,
        response: body,
        previousResponses: previousResponses
      }

      const postPreviewAnswer = await previewService.surveyPreview(this.mountedSurvey._id, response)
      if (postPreviewAnswer) {
        const findDuplicatedIdx = previousResponses.findIndex(i => i.question === body.question)
        if (findDuplicatedIdx >= 0) {
          this.$store.commit('preview/removePreviousResponse', findDuplicatedIdx)
        }
        res = postPreviewAnswer
        this.$store.commit('preview/addPreviousResponse', body)
      }

      // answersList
      const questionOptions = responseHelper.getOptions(this.mountedQuestion)
      if (['single', 'singleOther', 'multiple', 'multipleOther'].indexOf(this.mountedQuestion.ui) > -1 || ['matrix', 'referral'].indexOf(this.mountedQuestion.type) > -1) {
        this.insertAnswer(this.currentValue.value)
      } else if (this.mountedQuestion.ui === 'text') {
        if (this.currentValue.skip) {
          this.insertAnswer('')
        } else {
          if (this.currentValue.text.length > 100) {
            this.currentValue.text = this.currentValue.text.slice(0, 200) + '...'
          }
          this.insertAnswer(this.currentValue.text)
        }
      } else {
        if (this.currentValue.skip) {
          this.insertAnswer('')
        } else {
          const labelSelected = questionOptions.find(option => {
            let response = ''
            this.currentValue.rating >= 0 ? response = this.currentValue.rating.toString() : response = this.currentValue.value.toString()
            if (response) {
              if ((option.value || option.rating || option.label) === response) { return option }
            }
          })
          if (labelSelected !== undefined) {
            this.insertAnswer(labelSelected.label)
          } else {
            this.insertAnswer('')
          }
        }
      }

      if (myTransition) {
        this.loading = true
        myTransition.classList.add(classTransition)
      }

      setTimeout(() => {
        // final request
        this.currentValue = null
        this.updateQuestionPreview(res.data)
        if (myTransition) {
          myTransition.classList.toggle(classTransition)
        }
      }, 900)
    },
    insertAnswer (value) {
      this.$store.commit('answersList/setAnswer', { id: this.mountedQuestion.id, value: value })
    },
    closeSurveyPreview () {
      this.$store.commit('end/setEndMessage', this.mountedSurvey.endMessages)
      this.$router.push({ name: 'End' })
    },
    updateQuestionPreview (e) {
      let myTransition
      let classTransition
      if (this.myAnimation.show) {
        if (this.myAnimation.name === 'insideBox') {
          myTransition = document.querySelector('.content-survey')
          classTransition = 'closing'
        }

        if (this.myAnimation.name === 'tilesPage') {
          myTransition = document.querySelector('.animation')
          classTransition = 'active'
        }
      }

      if (myTransition) {
        myTransition.classList.add(classTransition)
      }

      this.display = false
      if (e.answeredMessage) {
        this.$store.commit('answered/setAnsweredMessage', e.answeredMessage)
        this.$router.push({ name: 'Answered' })
      } else if (e.endMessage) {
        const endMessage = []
        endMessage.push(e.endMessage)
        this.$store.commit('end/setEndMessage', endMessage)
        this.$router.push({ name: 'End' })
      } else {
        this.$store.commit('preview/setCurrentQuestion', e.nextQuestion)

        if (myTransition) {
          setTimeout(() => {
            myTransition.classList.remove(classTransition)
          }, 100)
        }

        this.returnAnimation()
        this.setCurrentLanguage()
        this.$root.$emit('next-bar-progress', this.$route.params.order - 1)

        setTimeout(() => {
          this.returnScreen()
          this.$root.$emit('hidden-logo-footer', true)
        }, 100)

        setTimeout(() => {
          this.loading = false
          if (this.myAnimation.name === 'insideBox') {
            this.returnScreen()
          }
        }, 950)
      }
    },
    before () {
      const previousList = this.$store.getters['preview/getPreviousResponses']
      const previous = previousList[previousList.length - 1]
      const foundPreviousQuestion = this.mountedSurvey.questions.find(i => i._id === previous.question)
      this.$store.commit('preview/setCurrentQuestion', foundPreviousQuestion)
      this.$store.commit('preview/removePreviousResponse', previousList.length - 1)
      this.$root.$emit('refresh-component')
      setTimeout(() => {
        this.returnScreen()
        this.$root.$emit('back-bar-progress', this.$route.params.order)
        this.refreshFooter++
      }, 10)
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-survey-prev {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bodyColor);
  .container-question-prev {
    position: relative;
    width: 650px;
    min-height: 650px;
    transition: width 0.6s, min-height 0.6s;
    border-radius: 10px;
    box-shadow: 0 8px 7px 5px rgba(0, 0, 0, 0.030);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .header-question {
      position: relative;
      width: 100%;
      min-height: 140px;
      padding: 10px 0;
      background: var(--headerBg);
      display: flex;
      justify-content: center;
      .back-question {
        position: absolute;
        top: 15px;
        left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .close-question {
        position: absolute;
        top: 15px;
        right: 5px;
      }
      .titles-question {
        width: 88%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p:first-child {
          margin-bottom: 10px;
        }
        p {
          color: var(--headerColor);
          font-size: 18px;
          text-align: center;
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
        }
      }
      .icon-header {
        font-size: 20px;
        font-weight: bold;
        color: var(--headerColor);
        background-image: linear-gradient(
          -60deg,
          transparent, transparent 40%,
          #ffffff44 40%, #ffffff44 60%,
          transparent 60%, transparent 100%
        );
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position-x: 150%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .icon-header:hover {
        background-position-x: -150%;
        transition: background-position-x 1s;
        cursor: pointer;
      }
    }
    .dynamic-question-prev {
      width: 100%;
      min-height: 370px;
      background: var(--splashBg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.animation-prev {
  z-index: 50;
}

.closing {
  width: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
}

// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .container-survey-prev {
    padding: 0;
    .container-question-prev {
      border-radius: 0 !important;
      .header-question {
        .titles-question {
          width: 82% !important;
          padding-top: 5px;
          p {
            font-size: 16px !important;
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
          }
        }
      }
      .dynamic-question-prev {
        min-height: var(--body-height) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
