<template>
    <div class="content-alert">
    <app-alert
        v-model="alertState.showAlert"
        :position="alertState.position"
        :color="alertState.color"
        :durationTime="alertState.durationTime"
        :persist="alertState.persist"
      >
      {{alertState.message}}
    </app-alert>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  computed: {
    alertState () {
      const alert = this.$store.getters['alerts/getAlertState']
      return alert
    }
  },
  components: {
    'app-alert': () => import('@binds-tech/binds-design-system/src/components/Alert/Alert')
  }
}
</script>

<style scoped lang="scss">
  .alert{
  }
  .alert--bottom-right{z-index: 50000 !important}
</style>
