const state = emptySurvey()

function emptySurvey () {
  return {
    account: '',
    answeredMessage: '',
    colors: [],
    endMessages: [],
    fonts: [],
    closeSurvey: '',
    loop: '',
    images: [],
    isLocked: true,
    nextLabel: '',
    questions: [],
    startMessage: {},
    surveyAlreadyResponded: false,
    title: '',
    _id: ''
  }
}

const mutations = {
  survey (state, survey) {
    Object
      .keys(survey)
      .forEach(key => {
        const value = survey[key]
        state[key] = value
        if (key === 'colors' && value.length) {
          state[key] = value.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        }
      })
  },
  upsertQuestion (state, updatedQuestion) {
    let found = false
    state.questions.map(q => {
      if (q.id === updatedQuestion.id) {
        found = true
        return Object.assign({}, q, updatedQuestion)
      }
    })
    if (!found) {
      state.questions.push(updatedQuestion)
    }
  },
  // função para dar o clear
  startEmptySurvey (state, params) {
    state = emptySurvey()
  },
  setSettings (state, payload) {
    state.settings = payload
  },
  theme (state, payload) {
    state.colors = payload
  },
  setSurvey (state, payload) {
    state = payload
  },
  setSurveyAlreadyResponded (state, payload) {
    state.surveyAlreadyResponded = payload
  },
  updateSurvey (state, payload) {
    state.survey = payload
  }
}

const getters = {
  getSurvey: state => {
    if (!state.colors.bodyColor) {
      state.colors.bodyColor = '#eff3f8'
    }
    if (!state.colors.splashBg) {
      state.colors.splashBg = '#fff'
    }
    if (!state.colors.headerBg) {
      state.colors.headerBg = '#fff'
    }
    return state
  },
  getNewSurvey: state => state.survey,
  getQuestions: state => state.questions,
  getStartMessage: state => state.startMessage,
  getEndMessage: state => state.endMessage,
  getSmallLogo: state => state.images && state.images[1] ? state.images[1].value : ''
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
