<template>
<div class="container-not-found" :style="{'min-height': isMobile, '--body-height': dynamicHeight }">
  <div class="content-not-found" :style="{'min-height': isMobile}">
    <img class="binds-logo-girl" src="../../public/img/unsub.svg" alt="binds-logo-girl">
    <a target="_blank" href="https://binds.co/pesquisa/conheca-a-binds-co/">
      <img class="binds-logo-small" src="../../public/img/favicon.png" alt="binds-logo-small">
    </a>
    <div class="section-not-found">
      <p class="message-not-found">{{ $t('survey_not_found') }}</p>
      <a target="_blank" href="https://binds.co/pesquisa/conheca-a-binds-co/">
        <img class="img-binds-logo" src="../assets/binds-end-message-logo.svg" alt="logo-binds">
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotFound',
  props: [],
  components: {},
  data () {
    return {
      isMobile: '',
      dynamicHeight: ''
    }
  },
  mounted () {
    this.returnScreen()
  },
  computed: {},
  methods: {
    returnScreen () {
      const screenWidth = window.screen.width
      const screenHeight = window.innerHeight
      if (screenWidth < 500) {
        this.isMobile = `${screenHeight}px`
        this.dynamicHeight = `${screenHeight - 140}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-not-found {
  background: var(--bodyColor);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-not-found {
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 650px;
    min-height: 650px;
    border-radius: 10px;
    padding: 0.5rem;
    padding-bottom: 1.7rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .binds-logo-girl {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .binds-logo-small {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: 35px;
    }
    .section-not-found {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      .message-not-found {
        color: #461EB8c7;
        text-shadow: #471eb878 1px 0 10px;
        font-size: 1.2vw;
        font-weight: 600;
      }
      .img-binds-logo {
        width: 65px;
      }
    }
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .message-not-found {
    font-size: 25px !important;
  }
  .img-binds-logo {
    width: 100px;
  }
}
</style>
