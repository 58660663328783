function getCurrentLocale (key) {
  const initial = {
    'pt-br': 'pt-BR',
    'en-us': 'en-US',
    'es-cl': 'es-CL'
  }
  return initial[key.toLowerCase()]
}

export const translator = { getCurrentLocale }
