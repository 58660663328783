const state = {
}
const mutations = {
  setShort (state, payload) {
    Object
      .keys(payload)
      .forEach(key => {
        const value = payload[key]
        state[key] = value
      })
  },
  clearShortState (state, payload) {
    state = payload
  }
}

const getters = {
  getShort: state => state
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
